import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import FormField from "components/FormField";
import GoogleAddressLookup from "components/GoogleComponents/AddressLookup";
import { useEffect } from "react";
import { useFormikContext } from "formik";

function SiteForm({ formData }) {
  const { setFieldValue } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const { siteName, siteAddress, operators, productionTarget } = formField;
  const {
    siteName: siteNameV,
    siteAddress: siteAddressV,
    operators: operatorsV,
    productionTarget: productionTargetV,
  } = values;

  useEffect(() => {
    if (siteAddressV === "") {
      setFieldValue("lat", "");
      setFieldValue("lng", "");
    }
  }, [siteAddressV]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add a site</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={siteName.type}
              label={siteName.label}
              name={siteName.name}
              value={siteNameV}
              placeholder={siteName.placeholder}
              error={errors.siteName && touched.siteName}
              success={siteNameV.length > 0 && !errors.siteName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GoogleAddressLookup
              marginBottom={1.5}
              type={siteAddress.type}
              label={siteAddress.label}
              name={siteAddress.name}
              value={siteAddressV}
              placeholder={siteAddress.placeholder}
              error={errors.siteAddress && touched.siteAddress}
              success={siteAddressV.length > 0 && !errors.siteAddress}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={operators.type}
              label={operators.label}
              name={operators.name}
              value={operatorsV}
              placeholder={operators.placeholder}
              error={errors.operators && touched.operators}
              success={operatorsV.length > 0 && !errors.operators}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={productionTarget.type}
              label={productionTarget.label}
              name={productionTarget.name}
              value={productionTargetV}
              placeholder={productionTarget.placeholder}
              error={errors.productionTarget && touched.productionTarget}
              success={productionTargetV.length > 0 && !errors.productionTarget}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MachineOptionForm
SiteForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SiteForm;

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import PageLayout from "layouts/LayoutContainers/PageLayout";
import Footer from "layouts/Footer";
import ProductImage from "components/ProductImage";
import ProductInfo from "./components/ProductInfo";

function SellingMachinePage() {
  const isAuthenicated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenicated ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={5}>
            <ProductImage />
          </Grid>
          <Grid item xs={12} lg={6} xl={7} sx={{ mx: "auto" }}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDBox mb={3}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Machine Details
                  </MDTypography>

                  <ProductInfo />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <PageLayout>
      <MDBox py={3} m={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={5}>
            <ProductImage />
          </Grid>
          <Grid item xs={12} lg={6} xl={7} sx={{ mx: "auto" }}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDBox mb={3}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Machine Details
                  </MDTypography>

                  <ProductInfo />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default SellingMachinePage;

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import FormField from "pages/operations/fleet/addNew/components/FormField";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";

function Location({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { siteId } = formField;
  const { siteId: siteIdV } = values;
  const siteOptions = useSelector((state) => state.sites.sites).map((s) => ({
    name: s.siteName,
    id: s.id,
  }));
  const { setFieldValue } = useFormikContext();

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Location
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              options={siteOptions}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => setFieldValue("siteId", value.id || "")}
              renderInput={(params) => (
                <FormField
                  marginBottom={1.5}
                  {...params}
                  type={siteId.type}
                  label={siteId.label}
                  name={siteId.name}
                  value={siteIdV}
                  placeholder={siteId.placeholder}
                  error={errors.siteId && touched.siteId}
                  success={siteId?.length > 0 && !errors.siteId}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Location
Location.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Location;

import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDButton from "components/common/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { useDispatch } from "react-redux";
import { saveUser } from "redux/actions/users";
import UserForm from "./components/UserForm";
import validation from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

function AddNewUser() {
  const { state } = useLocation();
  const { formId, formField } = form;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await dispatch(saveUser({ ...values, organisationId: state.organisationId }));
      navigate(-1);
    } catch {
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <UserForm
                        formData={{
                          values,
                          touched,
                          formField,
                          errors,
                          isSubmitting,
                        }}
                      />
                      <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          add
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewUser;

import { get, post, put } from "api";
import setAuthToken from "utils/setAuthToken";
import setAlert from "./alert";
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
} from "../constants";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await get("auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (details) => async (dispatch) => {
  try {
    const res = await post("users", details);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (credentials) => async (dispatch) => {
  try {
    const res = await post("auth", credentials);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("Login failed", "error"));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / clear profile

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const requestPasswordLink = (email) => async (dispatch) => {
  try {
    await post("auth/password", email);
    dispatch(setAlert("Password reset link sent", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("Login failed", "error"));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const resetPassword =
  ({ password }, id) =>
  async (dispatch) => {
    try {
      await put("auth/password", { id, password });
      dispatch(setAlert("Password has been reset, please login", "success"));
    } catch (err) {
      if (err.response) {
        const { errors } = err.response.data;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }
      } else {
        dispatch(setAlert("Login failed", "error"));
      }

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

import React, { useEffect, useState } from "react";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";

function Map({ defaultProps, polyline }) {
  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  };
  function fitBounds(map, maps) {
    const bounds = new maps.LatLngBounds();
    polyline.forEach((latLng) => {
      bounds.extend(latLng);
    });
    map.fitBounds(bounds);
  }
  useEffect(() => {
    reset();
  }, [polyline]);

  function renderPolylines(map, maps) {
    const flightPath = new maps.Polyline({
      path: polyline,
      geodesic: true,
      strokeColor: "#2c3c58",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flightPath.setMap(map);
    fitBounds(map, maps);
  }

  return (
    <Grid item xs={12} key={seed}>
      <Card>
        <MDBox variant="contained" bgColor="info" borderRadius="lg" coloredShadow="info">
          <div style={{ height: "40vh", width: "100%" }}>
            {polyline && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                defaultCenter={defaultProps?.center}
                defaultZoom={defaultProps?.zoom}
                onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
                options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
              />
            )}
          </div>
        </MDBox>
      </Card>
    </Grid>
  );
}

Map.propTypes = {
  defaultProps: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    zoom: PropTypes.number.isRequired,
    polygon: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  }).isRequired,
  polyline: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

export default Map;

import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/equip-go-banner.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { resetPassword } from "redux/actions/auth";
import { get } from "api";
import ResetPasswordForm from "./components/ResetPasswordForm";
import initialValues from "./schemas/initialValues";
import resetPasswordForm from "./schemas/reset-password-form";
import validations from "./schemas/validations";

function ResetPassword() {
  const [id, setId] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { formId, formField } = resetPasswordForm;
  const { resetPasswordToken } = useParams();
  const navigate = useNavigate();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  async function getId() {
    const response = await get(`auth/password/${resetPasswordToken}`);
    if (response.status === 200) {
      setId(response.data);
    }
  }

  useEffect(() => {
    getId();
  }, [resetPasswordToken]);

  const handleSubmit = async (values, actions) => {
    dispatch(resetPassword(values, id));
    navigate("/sign-in");
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Please enter a new password
          </MDTypography>
        </MDBox>
        <Formik
          initialValues={initialValues[3]}
          validationSchema={validations[3]}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox p={3}>
                <MDBox>
                  <ResetPasswordForm
                    formData={{
                      values,
                      touched,
                      formField,
                      errors,
                    }}
                  />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting}
                      type="submit"
                    >
                      reset
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Suddenly remembered?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Form>
          )}
        </Formik>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;

import React from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import MDTypography from "components/common/MDTypography";
import Footer from "layouts/Footer";
import { useNavigate } from "react-router-dom";

function Admin() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Card sx={{ cursor: "pointer" }} onClick={() => navigate("/organisation")}>
              <MDBox
                py={5}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Organisations
                  </MDTypography>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/list")}>
              <MDBox
                py={5}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Admins
                  </MDTypography>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/linkTable")}>
              <MDBox
                py={5}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Machine/Sensor link
                  </MDTypography>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/machineDetails")}>
              <MDBox
                py={5}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Machine Details
                  </MDTypography>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Admin;

const form = {
  formId: "new-user-form",
  formField: {
    organisationName: {
      name: "organisationName",
      label: "Organisation Name",
      type: "text",
      errorMsg: "Organisation name is required.",
    },
    organisationAddress: {
      name: "organisationAddress",
      label: "Organisation Detail",
      type: "text",
      errorMsg: "Organisation Detail is required.",
    },
    primaryContact: {
      name: "primaryContact",
      label: "Primary Contact",
      type: "text",
      errorMsg: "Primary Contact is required.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Your email address is invalid",
    },
    phone: {
      name: "phone",
      label: "Phone Number",
      type: "text",
      errorMsg: "Phone Number is required.",
    },
    lat: {
      name: "lat",
      label: "Latitude",
      type: "number",
      errorMsg: "Latitude is required",
    },
    lng: {
      name: "lng",
      label: "Longitude",
      type: "number",
      errorMsg: "Longitude is required",
    },
  },
};

export default form;

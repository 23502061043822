import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import FrequencySelector from "components/FrequencySelector";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PolylineMap from "components/GoogleComponents/polylineMap";
import { compareData, formatNumber, formatTime } from "utils/numberFunctions";
import Productivity from "../productivity";

function MachineReports({
  machineData,
  previousMachineData,
  label,
  timePeriod,
  polylineAvailable,
  polyline,
  selectedDate,
  tabValue,
  setTabValue,
  setSelectedDate,
}) {
  const { id } = useParams();
  const siteIdFromMachineId = useSelector(
    (state) => state.fleet.machines.find((m) => m.id === id)?.siteId.id
  );
  const site = useSelector((state) => state.sites.sites.find((s) => s.id === siteIdFromMachineId));

  const utilisation = (engineTime, idleTime) => {
    try {
      return ((engineTime - idleTime) / engineTime) * 100;
    } catch {
      return 0;
    }
  };

  const utilisationPercentage = (engineTime, idleTime) => {
    try {
      return formatNumber(utilisation(engineTime, idleTime), "%");
    } catch {
      return "0%";
    }
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FrequencySelector
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DefaultStatisticsCard
            title="Machine On Hours"
            count={formatTime(machineData?.engineTime)}
            percentage={compareData(
              machineData?.engineTime,
              previousMachineData?.engineTime,
              label
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DefaultStatisticsCard
            title="Active Hours"
            count={formatTime(machineData?.activeTime)}
            percentage={compareData(
              machineData?.activeTime,
              previousMachineData?.activeTime,
              label
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DefaultStatisticsCard
            title="Idle Hours"
            count={formatTime(machineData?.idleTime)}
            percentage={compareData(machineData?.idleTime, previousMachineData?.idleTime, label)}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DefaultStatisticsCard
            title="Utilisation"
            count={utilisationPercentage(machineData?.engineTime, machineData?.idleTime)}
            percentage={compareData(
              utilisation(machineData?.engineTime, machineData?.idleTime),
              utilisation(previousMachineData?.engineTime, previousMachineData?.idleTime),
              label
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DefaultStatisticsCard
            title={`${timePeriod} mileage`}
            count={formatNumber(machineData?.mileage, "km")}
            percentage={compareData(machineData?.mileage, previousMachineData?.mileage, label)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DefaultStatisticsCard
            title="Fuel Consumption"
            count={formatNumber(machineData?.fuelUsed, "L")}
            percentage={compareData(machineData?.fuelUsed, previousMachineData?.fuelUsed, label)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DefaultStatisticsCard
            title="Excess Fuel Consumption"
            count={formatNumber(machineData?.idleFuelUsed, "L")}
            percentage={compareData(
              machineData?.idleFuelUsed,
              previousMachineData?.idleFuelUsed,
              label
            )}
          />
        </Grid>

        {polyline && polylineAvailable && (
          <Grid item xs={12}>
            <PolylineMap
              defaultProps={{
                center: {
                  lat: site?.lat,
                  lng: site?.lng,
                },
                zoom: 13,
              }}
              polyline={polyline}
            />
          </Grid>
        )}
      </Grid>
      <Productivity selectedDate={selectedDate} tabValue={tabValue} />
    </>
  );
}

// add propTypes but do not use object or array
MachineReports.propTypes = {
  machineData: PropTypes.shape({
    engineTime: PropTypes.number,
    idleTime: PropTypes.number,
    mileage: PropTypes.number,
    batteryLevel: PropTypes.number,
    batteryVoltage: PropTypes.number,
    fuelUsed: PropTypes.number,
    idleFuelUsed: PropTypes.number,
    activeTime: PropTypes.number,
  }),
  previousMachineData: PropTypes.shape({
    engineTime: PropTypes.number,
    idleTime: PropTypes.number,
    mileage: PropTypes.number,
    batteryLevel: PropTypes.number,
    batteryVoltage: PropTypes.number,
    fuelUsed: PropTypes.number,
    idleFuelUsed: PropTypes.number,
    activeTime: PropTypes.number,
  }),
  label: PropTypes.string,
  timePeriod: PropTypes.string,
  polylineAvailable: PropTypes.bool,
  polyline: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDate: PropTypes.string,
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func,
  setSelectedDate: PropTypes.func,
};

// add defaultProps
MachineReports.defaultProps = {
  machineData: {},
  previousMachineData: {},
  label: "",
  timePeriod: "",
  polylineAvailable: false,
  polyline: [],
  selectedDate: "",
  tabValue: 0,
  setTabValue: () => {},
  setSelectedDate: () => {},
};

export default MachineReports;

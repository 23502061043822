import Dashboard from "pages/dashboard";
import Fleet from "pages/operations/fleet";
import Sites from "pages/operations/sites";
import Acquire from "pages/acquire";
import Admin from "pages/admin";
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    route: "/",
    component: <Dashboard />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    isAdmin: false,
  },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Operations", key: "title-operations" },
  {
    type: "collapse",
    name: "Fleet",
    key: "fleet",
    route: "/fleet",
    component: <Fleet />,
    icon: <Icon fontSize="medium">precision_manufacturing</Icon>,
    noCollapse: true,
    isAdmin: false,
  },
  {
    type: "collapse",
    name: "Sites",
    key: "sites",
    route: "/sites",
    component: <Sites />,
    icon: <Icon fontSize="medium">grass</Icon>,
    noCollapse: true,
    isAdmin: false,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Market", key: "title-marketplace" },
  {
    type: "collapse",
    name: "Acquire",
    key: "acquire",
    route: "/acquire",
    component: <Acquire />,
    icon: <Icon fontSize="medium">shopping_cart</Icon>,
    noCollapse: true,
    isAdmin: false,
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    route: "/admin",
    component: <Admin />,
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    noCollapse: true,
    isAdmin: true,
  },
];

export default routes;

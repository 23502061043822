import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import FormField from "pages/operations/fleet/addNew/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";

function Detail({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { yearOfManufacture, hours, driver, ownership, serviceDue } = formField;
  const {
    yearOfManufacture: yearOfManufactureV,
    hours: hoursV,
    driver: driverV,
    ownership: ownershipV,
    serviceDue: serviceDueV,
  } = values;

  const { setFieldValue } = useFormikContext();

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Detail
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={yearOfManufacture.type}
              label={yearOfManufacture.label}
              name={yearOfManufacture.name}
              value={yearOfManufactureV}
              placeholder={yearOfManufacture.placeholder}
              error={errors.yearOfManufacture && touched.yearOfManufacture}
              success={yearOfManufactureV.length > 0 && !errors.yearOfManufacture}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={hours.type}
              label={hours.label}
              name={hours.name}
              value={hoursV}
              placeholder={hours.placeholder}
              error={errors.hours && touched.hours}
              success={hoursV.length > 0 && !errors.hours}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={driver.type}
              label={driver.label}
              name={driver.name}
              value={driverV}
              placeholder={driver.placeholder}
              error={errors.driver && touched.driver}
              success={driverV.length > 0 && !errors.driver}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={["Owned", "Rented"]}
              onChange={(e, value) => setFieldValue("ownership", value || "")}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={ownership.type}
                  label={ownership.label}
                  name={ownership.name}
                  value={ownershipV}
                  placeholder={ownership.placeholder}
                  error={errors.ownership && touched.ownership}
                  success={ownershipV.length > 0 && !errors.ownership}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={serviceDue.type}
              label={serviceDue.label}
              name={serviceDue.name}
              value={serviceDueV}
              placeholder={serviceDue.placeholder}
              error={errors.serviceDue && touched.serviceDue}
              success={serviceDueV.length > 0 && !errors.serviceDue}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Detail
Detail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Detail;

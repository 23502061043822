const form = {
  formId: "new-machine-form",
  formField: {
    make: {
      name: "make",
      label: "Make",
      type: "text",
      errorMsg: "Make is required.",
    },
    model: {
      name: "model",
      label: "Model",
      type: "text",
      errorMsg: "Model is required.",
    },
    operations: {
      name: "operations",
      label: "Operations",
      type: "text",
      errorMsg: "Operations is required.",
    },
    fuel: {
      name: "fuel",
      label: "Fuel",
      type: "text",
      errorMsg: "Fuel is required.",
    },
    imageURL: {
      name: "imageURL",
      label: "Image URL",
      type: "text",
      errorMsg: "Image URL is required.",
    },
    kwh: {
      name: "kwh",
      label: "kwh",
      type: "number",
      errorMsg: "kwh is required.",
    },
  },
};

export default form;

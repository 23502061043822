import { get, post, del, put } from "api";
import { CREATE_OPTION, GET_OPTIONS, OPTIONS_ERROR } from "../constants";
import setAlert from "./alert";

// Load list of options
export const loadAllOptions = () => async (dispatch) => {
  try {
    const res = await get("options");
    dispatch({
      type: GET_OPTIONS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: OPTIONS_ERROR,
      payload: { msg: err },
    });
  }
};

// Save a option
export const saveOption = (option) => async (dispatch) => {
  try {
    const res = await post("options", option);
    dispatch({
      type: CREATE_OPTION,
      payload: res.data,
    });
    dispatch(loadAllOptions());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: OPTIONS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// delete a option
export const deleteOption = (id) => async (dispatch) => {
  try {
    await del(`options/${id}`);
    dispatch(loadAllOptions());

    dispatch(setAlert("Option deleted successfully", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: OPTIONS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// update an option
export const updateOption = (id, option) => async (dispatch) => {
  try {
    await put(`options/${id}`, option);
    dispatch(loadAllOptions());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: OPTIONS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

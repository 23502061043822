import { CREATE_OPTION, GET_OPTIONS, LOGOUT, OPTIONS_ERROR } from "../constants";

const initialState = {
  options: [],
  loading: true,
  error: null,
};

export default function options(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_OPTION:
      return {
        ...state,
        options: [payload, ...state.options],
        loading: false,
      };
    case GET_OPTIONS:
      return {
        ...state,
        options: payload,
        loading: false,
      };
    case OPTIONS_ERROR:
      return {
        options: [],
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

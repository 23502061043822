import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import DataTable from "components/Tables/DataTable";
import MDButton from "components/common/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { deleteOrganisation } from "redux/actions/organisations";
import userTableData from "./data/organisationUserTableData";

function DeleteAccount() {
  const { users } = useSelector((state) => state.users);
  const { columns, rows } = userTableData(users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDelete = () => {
    dispatch(deleteOrganisation(id));
    navigate("/organisation");
  };

  return (
    <Card id="user-table">
      {users?.length > 0 && (
        <MDBox pt={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      )}
      <MDBox p={3}>
        <MDButton variant="contained" color="error" size="large" fullWidth onClick={handleDelete}>
          Delete Organisation
        </MDButton>
      </MDBox>
    </Card>
  );
}

export default DeleteAccount;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// React components
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDBadge from "components/common/MDBadge";

export default function data(fleet) {
  const Brand = ({ make, model }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {make}
      </MDTypography>
      <MDTypography variant="caption">{model}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Serial Number", accessor: "serialNumber", align: "left" },
      { Header: "brand", accessor: "brand", align: "left" },
      { Header: "site", accessor: "site", align: "center" },
      { Header: "Asset Id", accessor: "assetId", align: "center" },
      { Header: "VIN", accessor: "VIN", align: "center" },
      { Header: "Fuel", accessor: "fuel", align: "center" },
      { Header: "Operation", accessor: "operations", align: "center" },
      { Header: "Year of Manufacture", accessor: "yearOfManufacture", align: "center" },
      { Header: "Hours", accessor: "hours", align: "center" },
      { Header: "Driver", accessor: "driver", align: "center" },
      { Header: "Ownership", accessor: "ownership", align: "center" },
      { Header: "Service Due", accessor: "serviceDue", align: "center" },
      { Header: "Status", accessor: "isActive", align: "center" },
    ],

    rows:
      fleet &&
      fleet.map((machine) => ({
        serialNumber: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.serialNumber}
          </MDTypography>
        ),
        brand: <Brand make={machine.make} model={machine.model} />,
        site: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine?.siteId?.siteName}
          </MDTypography>
        ),
        assetId: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.assetId}
          </MDTypography>
        ),
        VIN: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.VIN}
          </MDTypography>
        ),
        fuel: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.fuel}
          </MDTypography>
        ),
        operations: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.operations}
          </MDTypography>
        ),
        yearOfManufacture: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.yearOfManufacture}
          </MDTypography>
        ),
        hours: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.hours}
          </MDTypography>
        ),
        driver: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.driver}
          </MDTypography>
        ),
        ownership: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.ownership}
          </MDTypography>
        ),
        serviceDue: (
          <MDBadge
            badgeContent={new Date(machine.serviceDue) <= new Date() ? "Yes" : "No"}
            color={new Date(machine.serviceDue) <= new Date() ? "error" : "success"}
            variant="gradient"
            size="lg"
          />
        ),
        isActive: (
          <MDBadge
            badgeContent={machine.isActive?.data ? "Active" : "Inactive"}
            color={machine.isActive?.data ? "success" : "error"}
            variant="gradient"
            size="lg"
          />
        ),
        id: machine.id,
      })),
  };
}

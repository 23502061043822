import React, { useEffect, useState } from "react";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import Marker from "./Marker";

function Map({ defaultProps, machines, sites, height, skipFitBounds }) {
  const [seed, setSeed] = useState(1);

  const reset = () => {
    setSeed(Math.random());
  };

  function fitBounds(map, maps) {
    const bounds = new maps.LatLngBounds();
    if (sites.length > 0) {
      sites.forEach((site) => {
        bounds.extend(new maps.LatLng(site.lat, site.lng));
      });
    }
    if (machines.length > 0) {
      machines.forEach((machine) => {
        bounds.extend(new maps.LatLng(machine.lat, machine.lng));
      });
    }
    map.fitBounds(bounds);
  }

  useEffect(() => {
    reset();
  }, [machines]);

  return (
    <Grid item xs={12} key={seed}>
      <Card>
        {machines && (
          <MDBox variant="contained" bgColor="info" borderRadius="lg" coloredShadow="info">
            <div style={{ height, width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                defaultCenter={defaultProps?.center}
                defaultZoom={defaultProps?.zoom}
                options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                onGoogleApiLoaded={({ map, maps }) => {
                  if (!skipFitBounds) {
                    fitBounds(map, maps);
                  }
                }}
              >
                {machines?.map((marker) => (
                  <Marker lat={marker?.lat} lng={marker?.lng} text="M" key={marker?.key} />
                ))}
                {sites?.map((marker) => (
                  <Marker lat={marker?.lat} lng={marker?.lng} isSite key={marker?.key} />
                ))}
              </GoogleMapReact>
            </div>
          </MDBox>
        )}
      </Card>
    </Grid>
  );
}

Map.defaultProps = {
  skipFitBounds: false,
  height: "40vh",
  defaultProps: {
    center: {
      lat: 51.505,
      lng: -0.09,
    },
    zoom: 6,
  },
  machines: [],
  sites: [],
};

Map.propTypes = {
  skipFitBounds: PropTypes.bool,
  height: PropTypes.string,
  defaultProps: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    zoom: PropTypes.number.isRequired,
  }),
  machines: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      key: PropTypes.number.isRequired,
    })
  ),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      key: PropTypes.number.isRequired,
    })
  ),
};

export default Map;

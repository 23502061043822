import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MDTypography from "../common/MDTypography";

function Modal({ children, open, onClose, title, actions }) {
  const darkMode = useSelector((state) => state.ui?.darkMode);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#1e1e1e" : "#fff",
        },
      }}
      maxWidth="md"
    >
      {title && (
        <DialogTitle id="form-dialog-title">
          <MDTypography>{title}</MDTypography>
        </DialogTitle>
      )}
      <DialogContent>
        <MDTypography>{children}</MDTypography>
      </DialogContent>
      <DialogActions>
        {actions.map(({ label, onClick, color }) => (
          <Button key={label} onClick={onClick} color={color}>
            {label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

Modal.defaultProps = {
  title: null,
  actions: [],
};

export default Modal;

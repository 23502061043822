import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    serialNumber,
    make,
    model,
    siteId,
    serviceDue,
    ownership,
    driver,
    assetId,
    PIN,
    VIN,
    yearOfManufacture,
    hours,
    type,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [serialNumber.name]: Yup.string().required(serialNumber.errorMsg),
    [make.name]: Yup.string().required(make.errorMsg),
    [model.name]: Yup.string().required(model.errorMsg),
    [assetId.name]: Yup.string().required(assetId.errorMsg),
    [PIN.name]: Yup.string().required(PIN.errorMsg),
    [VIN.name]: Yup.string().required(VIN.errorMsg),
    [type.name]: Yup.string().required(type.errorMsg),
  }),
  Yup.object().shape({
    [serviceDue.name]: Yup.string().required(serviceDue.errorMsg),
    [ownership.name]: Yup.string().required(ownership.errorMsg),
    [driver.name]: Yup.string().required(driver.errorMsg),
    [yearOfManufacture.name]: Yup.string().required(yearOfManufacture.errorMsg),
    [hours.name]: Yup.string().required(hours.errorMsg),
  }),
  Yup.object().shape({
    [siteId.name]: Yup.string().required(siteId.errorMsg),
  }),
];

export default validations;

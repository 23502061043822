import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { siteName, siteAddress, operators, productionTarget },
} = checkout;

const validations = [
  Yup.object().shape({
    [siteName.name]: Yup.string().required(siteName.errorMsg),
    [siteAddress.name]: Yup.string().required(siteAddress.errorMsg),
    [operators.name]: Yup.string().required(operators.errorMsg),
    [productionTarget.name]: Yup.string().required(productionTarget.errorMsg),
  }),
];

export default validations;

import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/common/MDBox";
import Sidenav from "layouts/Sidenav";
import Configurator from "components/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { setMiniSidenav, setOpenConfigurator } from "redux/actions/UI";
import brandWhite from "assets/images/logos/EquipGo-light-logo.png";
import brandDark from "assets/images/logos/EquipGo-dark-logo.png";
import UserSignUp from "pages/auth/sign-up";
import UserSignIn from "pages/auth/sign-in";
import setAuthToken from "utils/setAuthToken";
import { loadUser } from "redux/actions/auth";
import Organisation from "pages/organisation";
import Admin from "pages/admin";
import Alert from "components/Alert";
import PrivateRoute from "components/routes/PrivateRoute";
import AddNewSite from "pages/operations/sites/addNew";
import AddNewMachine from "pages/operations/fleet/addNew";
import { useDispatch, useSelector } from "react-redux";
import AdminList from "pages/admin/list";
import AddNewAdmin from "pages/admin/addNew";
import AddNewOrganisation from "pages/organisation/addNew";
import OrganisationDetail from "pages/organisation/organisationDetail";
import AddNewUser from "pages/organisation/organisationDetail/components/OrgisationUserTable/addNewUser";
import ForgotPassword from "pages/auth/forgotPassword";
import ResetPassword from "pages/auth/resetPassword";
import { loadAllSites } from "redux/actions/sites";
import { loadAllMachines } from "redux/actions/fleet";
import FleetDetail from "pages/operations/fleet/fleetDetail";
import SiteDashboard from "pages/operations/sites/siteDashboard";
import SellingMachinePage from "pages/acquire/machinePage";
import Acquire from "pages/acquire";
import MachineDetailsList from "pages/admin/machineOptionsList";
import MachineOptionsAdd from "pages/admin/machineOptionsList/addEditMachine";
import Sensor from "pages/admin/sensor";
import AddSensorIdent from "pages/admin/sensor/addSensorIdent";

export default function App() {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth?.user?.isAdmin);
  const {
    openConfigurator,
    miniSidenav,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = useSelector((state) => state.ui);

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadAllSites());
      dispatch(loadAllMachines());
    }
  }, [isAuthenticated]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && !route.isAdmin && !isAdmin) {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      }
      if (route.route && !route.isAdmin && isAdmin) {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Alert />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="EquipGo"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="/sign-in" element={<UserSignIn />} />
        <Route path="/sign-up" element={<UserSignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetPasswordToken" element={<ResetPassword />} />
        {isAdmin && (
          <>
            <Route
              path="/organisation"
              element={
                <PrivateRoute>
                  <Organisation />
                </PrivateRoute>
              }
            />
            <Route
              path="/organisation/add-new"
              element={
                <PrivateRoute>
                  <AddNewOrganisation />
                </PrivateRoute>
              }
            />
            <Route
              path="/organisation/user/add-new"
              element={
                <PrivateRoute>
                  <AddNewUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/organisation/:id"
              element={
                <PrivateRoute>
                  <OrganisationDetail />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/add-new"
              element={
                <PrivateRoute>
                  <AddNewAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/list"
              element={
                <PrivateRoute>
                  <AdminList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/machineDetails"
              element={
                <PrivateRoute>
                  <MachineDetailsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/machineDetails/:id"
              element={
                <PrivateRoute>
                  <MachineOptionsAdd />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/linkTable"
              element={
                <PrivateRoute>
                  <Sensor />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/linkTable/:id"
              element={
                <PrivateRoute>
                  <AddSensorIdent />
                </PrivateRoute>
              }
            />
          </>
        )}

        <Route path="/acquire/:id" element={<SellingMachinePage />} />
        <Route path="/acquire/list" element={<Acquire />} />
        <Route path="/acquire/list/:id" element={<SellingMachinePage />} />

        <Route
          path="/sites/add-new"
          element={
            <PrivateRoute>
              <AddNewSite />
            </PrivateRoute>
          }
        />
        <Route
          path="/sites/:id"
          element={
            <PrivateRoute>
              <SiteDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/fleet/add-new"
          element={
            <PrivateRoute>
              <AddNewMachine />
            </PrivateRoute>
          }
        />
        <Route
          path="/fleet/:id"
          element={
            <PrivateRoute>
              <FleetDetail />
            </PrivateRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

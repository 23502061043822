import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDInput from "../common/MDInput";

export default function FrequencySelector({
  selectedDate,
  setTabValue,
  tabValue,
  setSelectedDate,
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleDateChange = (date) => setSelectedDate(date);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={7}>
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
            <Tab label="Day" />
            <Tab label="Week" />
            <Tab label="Month" />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12} sm={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {tabValue === 0 && (
            <DatePicker
              views={["day"]}
              label="Day"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <MDInput {...params} />}
            />
          )}
          {tabValue === 1 && (
            <DatePicker
              views={["day"]}
              label="Week"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <MDInput {...params} />}
            />
          )}
          {tabValue === 2 && (
            <DatePicker
              views={["month"]}
              label="Month"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <MDInput {...params} />}
            />
          )}
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

FrequencySelector.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  setTabValue: PropTypes.func,
  tabValue: PropTypes.number,
  setSelectedDate: PropTypes.func,
};

FrequencySelector.defaultProps = {
  selectedDate: new Date(),
  setTabValue: () => {},
  tabValue: 0,
  setSelectedDate: () => {},
};

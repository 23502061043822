import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import FrequencySelector from "components/FrequencySelector";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Overview from "./components/overview";
import Productivity from "./components/productivity";
import Finance from "./components/finance";

function SiteDashboard() {
  const [tabValue, setTabValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [statTab, setStatTab] = useState(0);

  const handleStatTabChange = (event, newValue) => {
    setStatTab(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <FrequencySelector
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              tabValue={tabValue}
              setTabValue={setTabValue}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <AppBar position="static">
              <Tabs value={statTab} onChange={handleStatTabChange}>
                <Tab
                  label="Overview"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      summarize
                    </Icon>
                  }
                  sx={{ px: 2 }}
                />
                <Tab
                  label="Productivity"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      precision_manufacturing
                    </Icon>
                  }
                  sx={{ px: 2 }}
                />
                <Tab
                  label="Finance"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      money
                    </Icon>
                  }
                  sx={{ px: 2 }}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {statTab === 0 && <Overview tabValue={tabValue} selectedDate={selectedDate} />}
        {statTab === 1 && <Productivity tabValue={tabValue} selectedDate={selectedDate} />}
        {statTab === 2 && <Finance tabValue={tabValue} selectedDate={selectedDate} />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SiteDashboard;

import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import { useSelector } from "react-redux";

export default function siteData(sites) {
  const machines = useSelector((state) => state.fleet.machines);

  return {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "address", accessor: "address", align: "left" },
      { Header: "Forklift", accessor: "forklift", align: "center" },
      { Header: "Wheel Loader", accessor: "wheelLoader", align: "center" },
      { Header: "Dumper", accessor: "dumper", align: "center" },
      { Header: "Haul Truck", accessor: "haulTruck", align: "center" },
      { Header: "Generator", accessor: "generator", align: "center" },
      { Header: "Mobile Crusher", accessor: "mobileCrusher", align: "center" },
      { Header: "Mobile Screener", accessor: "mobileScreener", align: "center" },
      { Header: "Conveyor", accessor: "conveyor", align: "center" },
      { Header: "Processing Plant", accessor: "processingPlant", align: "center" },
      { Header: "Drill", accessor: "drill", align: "center" },
      { Header: "Aerial Work Platform", accessor: "aerialWorkPlatform", align: "center" },
      { Header: "Telehandler", accessor: "telehandler", align: "center" },
      { Header: "Number of operators", accessor: "operators", align: "center" },
      { Header: "Monthly Production Target", accessor: "productionTarget", align: "center" },
      { Header: "View Site", accessor: "viewSite", align: "center" },
    ],

    rows:
      sites &&
      sites.map((site) => ({
        name: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {site.siteName}
          </MDTypography>
        ),
        address: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {site.siteAddress}
          </MDTypography>
        ),
        forklift: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Forklift").length}
          </MDTypography>
        ),
        wheelLoader: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Wheel Loader").length}
          </MDTypography>
        ),
        dumper: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Dumper").length}
          </MDTypography>
        ),
        haulTruck: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Haul Truck").length}
          </MDTypography>
        ),
        generator: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Generator").length}
          </MDTypography>
        ),
        mobileCrusher: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Mobile Crusher").length}
          </MDTypography>
        ),
        mobileScreener: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Mobile Screener").length}
          </MDTypography>
        ),
        conveyor: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Conveyor").length}
          </MDTypography>
        ),
        processingPlant: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {
              machines.filter((m) => m.siteId.id === site.id && m.type === "Processing Plant")
                .length
            }
          </MDTypography>
        ),
        drill: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Drill").length}
          </MDTypography>
        ),
        aerialWorkPlatform: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {
              machines.filter((m) => m.siteId.id === site.id && m.type === "Aerial Work Platform")
                .length
            }
          </MDTypography>
        ),
        telehandler: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machines.filter((m) => m.siteId.id === site.id && m.type === "Telehandler").length}
          </MDTypography>
        ),
        operators: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {site.operators}
          </MDTypography>
        ),
        productionTarget: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {site.productionTarget}
          </MDTypography>
        ),
        viewSite: (
          <MDButton variant="outlined" color="dark">
            View Site
          </MDButton>
        ),
        id: site.id,
      })),
  };
}

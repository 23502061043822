import { useSelector } from "react-redux";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import PageLayout from "layouts/LayoutContainers/PageLayout";
import Stack from "@mui/material/Stack";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Map from "components/GoogleComponents/map";
import mockLabels from "mocks/productivityData";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import PieChart from "components/Charts/PieChart";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "api";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import MDBadgeDot from "components/common/MDBadgeDot";

function Dashboard() {
  const naviagte = useNavigate();
  const organisationId = useSelector((state) => state.auth?.user?.organisationId);
  const isAdmin = useSelector((state) => state.auth?.user?.isAdmin);
  const machines = useSelector((state) => state.fleet.machines);
  const sites = useSelector((state) => state.sites.sites);
  const [machineMarkers, setMachineMarkers] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNavigate = (path) => {
    naviagte(path);
  };

  useEffect(() => {
    async function fetchData() {
      const res = await get(`dashboard`);
      setData(res.data);
      setMachineMarkers(res.data.machineLocations);
    }
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  // get array of lat, lng and id from sites
  const siteMarkers = sites
    ?.filter((m) => m?.lat && m?.lng)
    .map((m) => ({
      lat: m?.lat,
      lng: m?.lng,
      key: m?.id,
    }));

  return isAdmin || organisationId ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DefaultStatisticsCard
              title="Total Fleet"
              count={machines?.length}
              onClick={() => handleNavigate("/fleet")}
              clickable
            />
          </Grid>
          <Grid item xs={6}>
            <DefaultStatisticsCard
              title="Total Sites"
              count={sites?.length}
              onClick={() => handleNavigate("/sites")}
              clickable
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox variant="contained" bgColor="info" borderRadius="lg" coloredShadow="info">
              <Map sites={siteMarkers} machines={machineMarkers} />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <PieChart
                icon={{ color: "success", component: "donut_small" }}
                title="Site efficiency"
                description="Average efficiency per site(%)"
                chart={data?.pieChartData}
                unitOfMeasurement="%"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDBox mb={3}>
              <DefaultLineChart
                color="success"
                title="Materials processed(coming soon)"
                description={
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="info" size="sm" badgeContent="Tonnes(T)" />
                  </MDBox>
                }
                chart={{
                  labels: mockLabels,
                  datasets: [
                    {
                      label: "Cost per ton",
                      data: Array.from({ length: 7 }, () => Math.floor(Math.random() * 100)),
                      color: "info",
                    },
                  ],
                }}
                loading={loading}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  ) : (
    <PageLayout>
      <Stack alignItems="center" marginTop={20}>
        <MDTypography variant="h4" fontWeight="medium">
          Please contact our sales team for access to your dashboard.
          <a href="mailto:d.mckillen@equip-go.co.uk" style={{ color: "#F1A539" }}>
            {" "}
            Email us
          </a>
        </MDTypography>
      </Stack>
    </PageLayout>
  );
}

export default Dashboard;

import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import DataTable from "components/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadAdminMachines } from "redux/actions/fleet";
import MDTypography from "../../../components/common/MDTypography";

function MachineDetailsList() {
  const dispatch = useDispatch();

  // load machine options
  useEffect(() => {
    dispatch(loadAdminMachines());
  }, [dispatch]);

  const linked = useSelector((state) => state.fleet?.linked);
  const unlinked = useSelector((state) => state.fleet?.unlinked);
  // build object array to take site name up a level
  const linkedMachines = linked.map((machine) => ({
    ...machine,
    siteName: machine?.siteId?.siteName,
  }));
  const unlinkedMachines = unlinked.map((machine) => ({
    ...machine,
    siteName: machine?.siteId?.siteName,
  }));

  const linkedTableData = {
    columns: [
      { Header: "serialNumber", accessor: "serialNumber" },
      { Header: "make", accessor: "make" },
      { Header: "model", accessor: "model" },
      { Header: "site Name", accessor: "siteName" },
      { Header: "Sensor Id", accessor: "ident" },
    ],
    rows: linkedMachines,
  };

  const unlinkedTableData = {
    columns: [
      { Header: "serialNumber", accessor: "serialNumber" },
      { Header: "make", accessor: "make" },
      { Header: "model", accessor: "model" },
      { Header: "siteName", accessor: "siteName" },
      { Header: "Sensor Id", accessor: "ident" },
    ],
    rows: unlinkedMachines,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={2}>
            <MDTypography variant="h5">Unlinked Machines</MDTypography>
          </MDBox>
          <DataTable table={unlinkedTableData} canSearch clickable />
        </Card>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={2}>
            <MDTypography variant="h5">Linked Machines</MDTypography>
          </MDBox>
          <DataTable table={linkedTableData} canSearch clickable />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MachineDetailsList;

import { useMemo } from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import configs from "components/Charts/PieChart/configs";
import { CircularProgress } from "@mui/material";

function PieChart({
  icon,
  title,
  description,
  height,
  chart,
  loading,
  unitOfMeasurement,
  measurementAtFront = false,
}) {
  const { data, options } = configs(
    chart?.labels || [],
    chart?.datasets || {},
    unitOfMeasurement,
    measurementAtFront
  );

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Pie data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  const noData = chart?.datasets?.data === undefined;
  if (noData) {
    return (
      <Card>
        <MDBox display="flex" justifyContent="center" alignItems="center" height={height}>
          <MDTypography variant="h6">No Data</MDTypography>
        </MDBox>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card>
        <MDBox display="flex" justifyContent="center" alignItems="center" height={height}>
          <CircularProgress color="info" size={100} />
        </MDBox>
      </Card>
    );
  }

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of PieChart
PieChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  loading: false,
  measurementAtFront: false,
};

// Typechecking props for the PieChart
PieChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  loading: PropTypes.bool,
  unitOfMeasurement: PropTypes.string.isRequired,
  measurementAtFront: PropTypes.bool,
};

export default PieChart;

import { get, post, del, put } from "api";
import { CREATE_MACHINE, GET_MACHINES, MACHINES_ERROR, GET_ADMIN_MACHINES } from "../constants";
import setAlert from "./alert";

// Load list of fleet
export const loadAllMachines = () => async (dispatch) => {
  try {
    const res = await get("fleet");
    dispatch({
      type: GET_MACHINES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: MACHINES_ERROR,
      payload: { msg: err },
    });
  }
};

// Save a machine
export const saveMachine = (machine) => async (dispatch) => {
  try {
    const res = await post("fleet", machine);
    dispatch({
      type: CREATE_MACHINE,
      payload: res.data,
    });

    dispatch(setAlert("Your machine has been saved", "success"));

    dispatch(loadAllMachines());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: MACHINES_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// delete a machine
export const deleteMachine = (id) => async (dispatch) => {
  try {
    await del(`fleet/${id}`);
    dispatch(loadAllMachines());

    dispatch(setAlert("Machine deleted successfully", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: MACHINES_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// delete a machine
export const updateMachine = (id, machine) => async (dispatch) => {
  try {
    await put(`fleet/${id}`, machine);
    dispatch(loadAllMachines());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: MACHINES_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// load machines
export const loadAdminMachines = () => async (dispatch) => {
  try {
    const res = await get("fleet/admin");

    // group machines by if they have a sensorId or not
    const groupedMachines = res.data.reduce(
      (acc, machine) => {
        if (machine.ident) {
          acc[0].push(machine);
        } else {
          acc[1].push(machine);
        }
        return acc;
      },
      [[], []]
    );

    dispatch({
      type: GET_ADMIN_MACHINES,
      payload: groupedMachines,
    });
    dispatch({
      type: GET_MACHINES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: MACHINES_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

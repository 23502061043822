import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { yearOfManufacture, site, serviceDue, hours },
} = checkout;

const validations = [
  Yup.object().shape({
    [yearOfManufacture.name]: Yup.string().required(yearOfManufacture.errorMsg),
    [site.name]: Yup.string().required(site.errorMsg),
    [serviceDue.name]: Yup.string().required(serviceDue.errorMsg),
    [hours.name]: Yup.string().required(hours.errorMsg),
  }),
];

export default validations;

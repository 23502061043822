import checkout from "./form";

const {
  formField: { firstName, lastName, email },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
};
export default initialValues;

import checkout from "./form";

const {
  formField: { make, model, operations, fuel, imageURL, kwh },
} = checkout;

const initialValues = {
  [make.name]: "",
  [model.name]: "",
  [operations.name]: "",
  [fuel.name]: "",
  [imageURL.name]: "",
  [kwh.name]: "",
};

export default initialValues;

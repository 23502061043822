import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import FormField from "components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function MachineUpdateForm({ formData }) {
  const siteOptions = useSelector((state) => state.sites.sites).map((s) => s.siteName);
  const sites = useSelector((state) => state.sites.sites);
  const { setFieldValue } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const { yearOfManufacture, site, serviceDue, hours, ownership, driver, type } = formField;
  const {
    yearOfManufacture: yearOfManufactureV,
    site: siteV,
    serviceDue: serviceDueV,
    hours: hoursV,
    ownership: ownershipV,
    driver: driverV,
    type: typeV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={siteOptions}
              onChange={(e, value) =>
                setFieldValue("siteId", sites.find((s) => s.siteName === value).id)
              }
              defaultValue={siteV}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={site.type}
                  label={site.label}
                  name={site.name}
                  value={siteV}
                  placeholder={site.placeholder}
                  error={errors.site && touched.site}
                  success={site?.length > 0 && !errors.site}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={yearOfManufacture.type}
              label={yearOfManufacture.label}
              name={yearOfManufacture.name}
              value={yearOfManufactureV}
              placeholder={yearOfManufacture.placeholder}
              error={errors.yearOfManufacture && touched.yearOfManufacture}
              success={yearOfManufactureV.length > 0 && !errors.yearOfManufacture}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["day"]}
                value={serviceDueV}
                onChange={(value) => setFieldValue("serviceDue", value)}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    marginBottom={1.5}
                    type={serviceDue.type}
                    label={serviceDue.label}
                    name={serviceDue.name}
                    placeholder={serviceDue.placeholder}
                    error={errors.serviceDue && touched.serviceDue}
                    success={serviceDueV.length > 0 && !errors.serviceDue}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={hours.type}
              label={hours.label}
              name={hours.name}
              value={hoursV}
              placeholder={hours.placeholder}
              error={errors.hours && touched.hours}
              success={hoursV.length > 0 && !errors.hours}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={ownershipV}
              options={["Owned", "Rented"]}
              onChange={(e, value) => setFieldValue("ownership", value || "")}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={ownership.type}
                  label={ownership.label}
                  name={ownership.name}
                  value={ownershipV}
                  placeholder={ownership.placeholder}
                  error={errors.ownership && touched.ownership}
                  success={ownershipV.length > 0 && !errors.ownership}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={driver.type}
              label={driver.label}
              name={driver.name}
              value={driverV}
              placeholder={driver.placeholder}
              error={errors.driver && touched.driver}
              success={driverV.length > 0 && !errors.driver}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={type.type}
              label={type.label}
              name={type.name}
              value={typeV}
              placeholder={type.placeholder}
              error={errors.type && touched.type}
              success={typeV.length > 0 && !errors.type}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MachineOptionForm
MachineUpdateForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MachineUpdateForm;

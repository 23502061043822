import { LOGOUT } from "../constants";

const initialState = {
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "info",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  layout: "dashboard",
  darkMode: false,
};

export default function ui(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: payload };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: payload };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: payload };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: payload };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: payload };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: payload };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: payload };
    }
    case "LAYOUT": {
      return { ...state, layout: payload };
    }
    case "DARKMODE": {
      return { ...state, darkMode: payload };
    }
    case LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

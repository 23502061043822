const form = {
  formId: "new-site-form",
  formField: {
    siteName: {
      name: "siteName",
      label: "Site Name",
      type: "text",
      errorMsg: "Site name is required.",
    },
    siteAddress: {
      name: "siteAddress",
      label: "Site Detail",
      type: "text",
      errorMsg: "Site address is required.",
    },
    operators: {
      name: "operators",
      label: "Number of Operators",
      type: "text",
      errorMsg: "Number of operators is required.",
    },
    productionTarget: {
      name: "productionTarget",
      label: "Monthly Production Target",
      type: "number",
      errorMsg: "Material is required.",
    },
    lat: {
      name: "lat",
      label: "Lat",
      type: "number",
      errorMsg: "lat is required.",
    },
    lng: {
      name: "lng",
      label: "Lng",
      type: "number",
      errorMsg: "lng is required.",
    },
  },
};

export default form;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { get, put } from "api";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import BarChart from "components/Charts/BarCharts/VerticalBarChart";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import { formatCurrency } from "utils/numberFunctions";
import MDInput from "components/common/MDInput";
import PieChart from "components/Charts/PieChart";
import { useParams } from "react-router-dom";
import MDButton from "components/common/MDButton";
import { NumericFormat } from "react-number-format";
import Icon from "@mui/material/Icon";
import MDBadgeDot from "components/common/MDBadgeDot";
import HorizontalBarChart from "components/Charts/BarCharts/HorizontalBarChart";
import { loadAllSites } from "redux/actions/sites";
import { Tooltip } from "@mui/material";
import { debounce } from "lodash";
import AdditionalCosts from "./components/additionalCosts";

function Finance({ selectedDate, tabValue }) {
  const dispatch = useDispatch();

  const { id } = useParams();
  const siteFuelCost = useSelector(
    (state) => state.sites?.sites?.find((site) => site?.id === id).fuelCost
  );
  const siteElectricityCost = useSelector(
    (state) => state.sites?.sites?.find((site) => site?.id === id).electricityCost
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fuelCost, setFuelCost] = useState(0);
  const [electricityCost, setElectricityCost] = useState(0);

  const [additionalCost, setAdditionalCost] = useState([]);
  const [totalAdditionalCost, setTotalAdditionalCost] = useState(0);

  async function fetchData() {
    const res = await get(
      `dashboard/finance?siteId=${id}&selectedDate=${selectedDate}&freq=${tabValue}`
    );
    setData(res.data);
  }

  async function handleDebounceFn(inputValue) {
    setLoading(true);
    put(`sites/${id}`, inputValue).then(() => {
      dispatch(loadAllSites());
      fetchData()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    });
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  function handleChange(value, name) {
    if (name === "fuelCost") {
      setFuelCost(value);
      debounceFn({ fuelCost: value });
    } else {
      setElectricityCost(value);
      debounceFn({ electricityCost: value });
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchData().then(() => setLoading(false));
  }, [selectedDate, tabValue, id]);

  useEffect(() => {
    let total = 0;
    additionalCost.forEach((cost) => {
      total += parseInt(cost.costValue, 10) || 0;
    });
    setTotalAdditionalCost(total);
  }, [additionalCost]);

  useEffect(() => {
    setFuelCost(siteFuelCost);
  }, [siteFuelCost]);

  useEffect(() => {
    setElectricityCost(siteElectricityCost);
  }, [siteElectricityCost]);

  return (
    <>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <NumericFormat
              name="fuelCost"
              label="Fuel Cost"
              variant="outlined"
              value={fuelCost}
              fullWidth
              onValueChange={(e) => {
                const { floatValue } = e;
                handleChange(floatValue, "fuelCost");
              }}
              decimalScale={2}
              fixedDecimalScale
              customInput={MDInput}
              thousandSeparator
              prefix="£"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <NumericFormat
              name="electricityCost"
              label="Electricity Cost"
              variant="outlined"
              value={electricityCost}
              fullWidth
              onValueChange={(e) => {
                const { floatValue } = e;
                handleChange(floatValue, "electricityCost");
              }}
              decimalScale={2}
              fixedDecimalScale
              customInput={MDInput}
              thousandSeparator
              prefix="£"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultStatisticsCard
              title="Total Additional Costs"
              count={formatCurrency(totalAdditionalCost, loading)}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
              title="Total Fuel Cost"
              count={
                fuelCost === 0 ? "Please add fuel cost" : formatCurrency(data.fuelUsed, loading)
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
              title="Total power cost"
              count={
                electricityCost === 0
                  ? "Please add power cost"
                  : formatCurrency(data.powerUsed, loading)
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
              title="Total operation cost"
              count={formatCurrency(data.fuelUsed + data.powerUsed + totalAdditionalCost, loading)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
              title="Cost per ton"
              count={formatCurrency(data.materialProcessed, loading)}
            />
          </Grid>
        </Grid>
        <MDBox mt={3}>
          <Grid spacing={3} container>
            <Grid item xs={12} md={4} lg={4}>
              <PieChart
                title="Operation costs"
                description="Total cost of each operations(£)"
                chart={data?.graphData?.pieChartData}
                loading={loading}
                unitOfMeasurement="£"
                measurementAtFront
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <HorizontalBarChart
                title="Costs"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Power(£)" />
                      <MDBadgeDot color="dark" size="sm" badgeContent="Fuel(£)" />
                      <MDBadgeDot color="secondary" size="sm" badgeContent="Total(£)" />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="Compare your daily utilisation" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={data?.graphData?.costsGraphData}
                loading={loading}
                id="myChart"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MDBox mt={3}>
                <BarChart
                  icon={{ color: "dark", component: "leaderboard" }}
                  title="Cost per ton"
                  chart={data?.graphData?.materialProcessedGraphData}
                  loading={loading}
                  color="success"
                  description={
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Cost per tonne(£)" />
                    </MDBox>
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <AdditionalCosts
                tabValue={tabValue}
                additionalCost={additionalCost}
                setAdditionalCost={setAdditionalCost}
                siteId={id}
                selectedDate={selectedDate}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

// selected date passed in as type date
Finance.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  tabValue: PropTypes.number.isRequired,
};

export default Finance;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// React components
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDBadge from "components/common/MDBadge";
import { useSelector } from "react-redux";

export default function data() {
  const loading = useSelector((state) => state.organisations?.loading);
  const organisations = useSelector((state) => state.organisations?.organisations);

  const OrganisationName = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Address = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "address", accessor: "address", align: "left" },
      { Header: "contact", accessor: "contact", align: "center" },
      { Header: "phone", accessor: "phone", align: "center" },
      { Header: "sites", accessor: "sites", align: "center" },
      { Header: "subscribed", accessor: "subscribed", align: "center" },
    ],

    rows:
      !loading &&
      organisations &&
      organisations.map((organisation) => ({
        name: (
          <OrganisationName name={organisation?.organisationName} email={organisation?.email} />
        ),
        address: <Address title={organisation.organisationAddress} description="BT36 4AD" />,
        contact: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {organisation.primaryContact}
          </MDTypography>
        ),
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {organisation.phone}
          </MDTypography>
        ),
        sites: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {(Math.random() * 10).toFixed(0)}
          </MDTypography>
        ),
        subscribed: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="paid" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        id: organisation.id,
      })),
  };
}

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

function PrivateRoute({ isAuthenticated, loading, children }) {
  return !loading && isAuthenticated ? children : <Navigate to="/sign-in" />;
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(PrivateRoute);

import Modal from "components/Modal";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDTypography from "components/common/MDTypography";
import PropTypes from "prop-types";
import { deleteMachine, updateMachine } from "redux/actions/fleet";
import Card from "@mui/material/Card";
import MDBadge from "components/common/MDBadge";
import MDBox from "components/common/MDBox";
import { useNavigate, useParams } from "react-router-dom";
import setAlert from "redux/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import MDButton from "components/common/MDButton";
import form from "../../schemas/form";
import validations from "../../schemas/validations";
import MachineUpdateForm from "../machineUpdateForm";

function MachineInfo({ isActive }) {
  const { id } = useParams();
  const machine = useSelector((state) => state.fleet.machines.find((m) => m.id === id));
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.ui.darkMode);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteMachine(id));
    navigate("/fleet");
  };

  const {
    formField: {
      yearOfManufacture,
      site,
      serviceDue,
      hours,
      totalKm,
      weight,
      ownership,
      driver,
      fuel,
      type,
    },
  } = form;

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await dispatch(updateMachine(id, values));
      actions.setSubmitting(false);
      dispatch(setAlert("Machine updated successfully", "success"));
    } catch {
      actions.setSubmitting(false);
      dispatch(setAlert("error", "Something went wrong"));
    }
  };
  return (
    <Card>
      <Modal
        onClose={handleClose}
        open={open}
        title={`Delete machine ${machine.serialNumber}`}
        actions={[
          { label: "Yes", onClick: handleDelete, color: "primary" },
          { label: "No", onClick: handleClose, color: "secondary" },
        ]}
      >
        Are you sure you wish to permanently delete this machine?
      </Modal>
      <MDBox p={3}>
        <MDBox mt={1}>
          <MDBox display="flex">
            <MDBadge
              badgeContent={isActive ? "Active" : "Inactive"}
              color={isActive ? "success" : "error"}
              variant="gradient"
              size="lg"
              sx={{ marginLeft: "auto" }}
            />
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="subtitle2">Make</MDTypography>
              <MDTypography variant="body1">{machine.make}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="subtitle2">Model</MDTypography>
              <MDTypography variant="body1">{machine.model}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="subtitle2">Serial Number</MDTypography>
              <MDTypography variant="body1">{machine.serialNumber}</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="subtitle2">VIN</MDTypography>
              <MDTypography variant="body1">{machine.VIN}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="subtitle2">PIN</MDTypography>
              <MDTypography variant="body1">{machine.PIN}</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="subtitle2">Asset ID</MDTypography>
              <MDTypography variant="body1">{machine.assetId}</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Formik
          initialValues={{
            [fuel.name]: machine.fuel || "",
            [yearOfManufacture.name]: machine.yearOfManufacture || "",
            [hours.name]: machine.hours || "",
            [driver.name]: machine.driver || "",
            [ownership.name]: machine.ownership || "",
            [type.name]: machine.type || "",
            [site.name]: machine.siteId.siteName || "",
            [serviceDue.name]: new Date(machine.serviceDue) || "",
            [hours.name]: machine.hours || "",
            [totalKm.name]: machine.totalKm || "",
            [weight.name]: machine.weight || "",
          }}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MachineUpdateForm
                formData={{
                  values,
                  touched,
                  formField,
                  errors,
                  isSubmitting,
                }}
              />
              <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                <MDBox mr={1}>
                  <MDButton
                    disabled={isSubmitting}
                    type="button"
                    variant="gradient"
                    color={darkMode ? "dark" : "light"}
                    onClick={() => setOpen(true)}
                  >
                    delete
                  </MDButton>
                </MDBox>
                <MDButton disabled={isSubmitting} type="submit" variant="gradient" color="dark">
                  update
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </Card>
  );
}

MachineInfo.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default MachineInfo;

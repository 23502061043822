const signInForm = {
  formId: "sign-in-form",
  formField: {
    email: {
      name: "email",
      label: "Email",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
  },
};

export default signInForm;

import { get, post, del, put } from "api";
import { CREATE_USER, GET_USERS, USERS_ERROR } from "../constants";
import setAlert from "./alert";

// Load list of users
export const loadAllUsers = (organisationId) => async (dispatch) => {
  try {
    const res = organisationId ? await get(`users/${organisationId}`) : await get("users");
    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: USERS_ERROR,
      payload: { msg: err },
    });
  }
};

// Save a user
export const saveUser = (user) => async (dispatch) => {
  try {
    const res = await post("users/addNew", user);
    dispatch({
      type: CREATE_USER,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: USERS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// delete a user
export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await del(`users/${id}`);
    dispatch(loadAllUsers(res.data.organisationId));

    dispatch(setAlert("User deleted successfully", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: USERS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// update user admin privileges
export const updateUser = (user) => async (dispatch) => {
  try {
    await put(`users/${user.id}`, user);
    dispatch(loadAllUsers());
    dispatch(setAlert("User updated successfully", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: USERS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// update user admin privileges by id
export const updateUserAdmin = (id) => async (dispatch) => {
  try {
    await put(`users/${id}/admin`);
    dispatch(loadAllUsers());
    dispatch(setAlert("User updated successfully", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: USERS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

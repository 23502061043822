import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import { useSelector } from "react-redux";

function DefaultStatisticsCard({ title, count, percentage, onClick, clickable }) {
  const darkMode = useSelector((state) => state.ui?.darkMode);

  return (
    <Card onClick={onClick} sx={{ cursor: clickable ? "pointer" : "" }}>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox mb={0.5} lineHeight={1}>
              <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="h5" fontWeight="bold">
                {count}
              </MDTypography>
              {percentage.label && (
                <MDTypography variant="button" fontWeight="bold" color={percentage.color}>
                  {percentage.value}&nbsp;
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={darkMode ? "text" : "secondary"}
                  >
                    {percentage.label}
                  </MDTypography>
                </MDTypography>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
DefaultStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  onClick: () => {},
  clickable: false,
};

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
};

export default DefaultStatisticsCard;

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/common/MDTypography";
import DataTable from "components/Tables/DataTable";
import Footer from "layouts/Footer";
import PageLayout from "layouts/LayoutContainers/PageLayout";
import { getMachinesForSale } from "redux/actions/acquire";
import fleetTableData from "./data/aquireTableData";

function Acquire() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // get machines for sale
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMachinesForSale());
  }, [dispatch]);
  const { columns, rows } = fleetTableData();

  return isAuthenticated ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Acquire
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  clickable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <PageLayout>
      <MDBox pt={6} pb={3} m={5}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Acquire
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  clickable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default Acquire;

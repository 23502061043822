/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// React components
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/numberFunctions";

export default function data() {
  const machinesForSale = useSelector((state) => state.acquire.machinesForSale);
  const Brand = ({ make, model }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {make}
      </MDTypography>
      <MDTypography variant="caption">{model}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "brand", accessor: "brand", align: "left" },
      { Header: "year", accessor: "year", align: "center" },
      { Header: "price", accessor: "price", align: "center" },
    ],

    rows:
      machinesForSale &&
      machinesForSale.map((machine) => ({
        brand: <Brand make={machine.make} model={machine.model} />,
        year: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {machine.year}
          </MDTypography>
        ),
        price: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {formatCurrency(machine?.price)}
          </MDTypography>
        ),
        id: machine.id,
      })),
  };
}

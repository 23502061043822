import checkout from "./form";

const {
  formField: { siteName, siteAddress, operators, productionTarget, lat, lng },
} = checkout;

const initialValues = {
  [siteName.name]: "",
  [siteAddress.name]: "",
  [operators.name]: "",
  [productionTarget.name]: "",
  [lat.name]: "",
  [lng.name]: "",
};

export default initialValues;

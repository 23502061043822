import { CREATE_SITE, GET_SITES, LOGOUT, SITES_ERROR } from "../constants";

const initialState = {
  sites: [],
  loading: true,
  error: null,
};

export default function sites(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SITE:
      return {
        ...state,
        sites: [payload, ...state.sites],
        loading: false,
      };
    case GET_SITES:
      return {
        ...state,
        sites: payload,
        loading: false,
      };
    case SITES_ERROR:
      return {
        sites: [],
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import FormField from "pages/operations/fleet/addNew/components/FormField";
import { useFormikContext } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";

function Description({ formData }) {
  const options = useSelector((state) => state.options.options);
  const { formField, values, errors, touched } = formData;
  const { assetId, PIN, make, model, type, VIN, serialNumber } = formField;
  const {
    assetId: assetIdV,
    PIN: PINV,
    make: makeV,
    model: modelV,
    type: typeV,
    VIN: VINV,
    serialNumber: serialNumberV,
  } = values;

  const { setFieldValue } = useFormikContext();

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add equipment</MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={serialNumber.type}
              label={serialNumber.label}
              name={serialNumber.name}
              value={serialNumberV}
              placeholder={serialNumber.placeholder}
              error={errors.serialNumber && touched.serialNumber}
              success={serialNumberV.length > 0 && !errors.serialNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={PIN.type}
              label={PIN.label}
              name={PIN.name}
              value={PINV}
              placeholder={PIN.placeholder}
              error={errors.PIN && touched.PIN}
              success={PINV.length > 0 && !errors.PIN}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={VIN.type}
              label={VIN.label}
              name={VIN.name}
              value={VINV}
              placeholder={VIN.placeholder}
              error={errors.VIN && touched.VIN}
              success={VINV.length > 0 && !errors.VIN}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={[
                "Forklift",
                "Excavator",
                "Wheel Loader",
                "Dumper",
                "Haul Truck",
                "Generator",
                "Mobile Crusher",
                "Mobile Screener",
                "Conveyor",
                "Processing Plant",
                "Drill",
                "Aerial Work Platform",
                "Telehandler",
              ]}
              onChange={(e, value) => setFieldValue("type", value || "")}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={type.type}
                  label={type.label}
                  name={type.name}
                  value={typeV}
                  placeholder={type.placeholder}
                  error={errors.type && touched.type}
                  success={typeV.length > 0 && !errors.type}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={[...new Set(options.map((m) => m.make))]}
              onChange={(e, value) => setFieldValue("make", value || "")}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={make.type}
                  label={make.label}
                  name={make.name}
                  value={makeV}
                  placeholder={make.placeholder}
                  error={errors.make && touched.make}
                  success={makeV?.length > 0 && !errors.make}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              // options should be unique models for the selected make
              options={options.filter((m) => m.make === makeV).map((m) => m.model)}
              onChange={(e, value) => setFieldValue("model", value || "")}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={model.type}
                  label={model.label}
                  name={model.name}
                  value={modelV}
                  placeholder={model.placeholder}
                  error={errors.model && touched.model}
                  success={modelV?.length > 0 && !errors.model}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={assetId.type}
              label={assetId.label}
              name={assetId.name}
              value={assetIdV}
              placeholder={assetId.placeholder}
              error={errors.assetId && touched.assetId}
              success={assetIdV.length > 0 && !errors.assetId}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for SignUp
Description.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Description;

import { del, get, post } from "api";
import { CREATE_ORGANISATION, GET_ORGANISATIONS, ORGANISATIONS_ERROR } from "../constants";
import setAlert from "./alert";

// Load list of organisations
export const loadOrganisations = () => async (dispatch) => {
  try {
    const res = await get("organisations");
    dispatch({
      type: GET_ORGANISATIONS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: ORGANISATIONS_ERROR,
      payload: { msg: err },
    });
  }
};

// Save an organisation
export const saveOrganisation = (organisation) => async (dispatch) => {
  try {
    const res = await post("organisations", organisation);
    dispatch({
      type: CREATE_ORGANISATION,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: ORGANISATIONS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// Delete an organisation
export const deleteOrganisation = (id) => async (dispatch) => {
  try {
    await del(`organisations/${id}`);
    dispatch(loadOrganisations());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: ORGANISATIONS_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

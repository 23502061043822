import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Footer from "layouts/Footer";
import DataTable from "components/Tables/DataTable";
import MDButton from "components/common/MDButton";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { loadAllUsers } from "redux/actions/users";
import adminUserTableData from "./data/adminUserTableData";

function AdminList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllUsers());
  }, []);
  const users = useSelector((state) => state.users.users);

  const navigate = useNavigate();

  const { columns, rows } = adminUserTableData(users);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Admin list
                  </MDTypography>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    iconOnly
                    onClick={() => navigate("/admin/add-new")}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminList;

import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import BaseLayout from "./components/BaseLayout";
import Header from "./components/Header";
import OrgisationUserTable from "./components/OrgisationUserTable";

function OrganisationDetail() {
  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <OrgisationUserTable />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default OrganisationDetail;

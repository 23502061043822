import { get, post, del } from "api";
import { CREATE_SITE, GET_SITES, SITES_ERROR } from "../constants";
import setAlert from "./alert";

// Load list of sites
export const loadAllSites = () => async (dispatch) => {
  try {
    const res = await get(`sites`);
    dispatch({
      type: GET_SITES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: SITES_ERROR,
      payload: { msg: err },
    });
  }
};

// Save a site
export const saveSite = (site) => async (dispatch) => {
  try {
    const res = await post("sites", site);
    dispatch({
      type: CREATE_SITE,
      payload: res.data,
    });
    dispatch(setAlert("Site created", "success"));
    dispatch(loadAllSites());
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: SITES_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

// delete a site
export const deleteSite = (id) => async (dispatch) => {
  try {
    await del(`sites/${id}`);
    dispatch(setAlert("Site deleted successfully", "success"));
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    }

    dispatch({
      type: SITES_ERROR,
      payload: { msg: err },
    });
    throw err;
  }
};

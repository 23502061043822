import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { make, model, fuel, operations },
} = checkout;

const validations = [
  Yup.object().shape({
    [make.name]: Yup.string().required(make.errorMsg),
    [model.name]: Yup.string().required(model.errorMsg),
    [operations.name]: Yup.string().required(operations.errorMsg).nullable(),
    [fuel.name]: Yup.string().required(fuel.errorMsg).nullable(),
  }),
];

export default validations;

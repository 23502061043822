import checkout from "./form";

const {
  formField: {
    serialNumber,
    make,
    model,
    siteId,
    assetId,
    PIN,
    VIN,
    fuel,
    yearOfManufacture,
    hours,
    driver,
    ownership,
    serviceDue,
    type,
  },
} = checkout;

const initialValues = {
  [serialNumber.name]: "",
  [make.name]: "",
  [model.name]: "",
  [siteId.name]: "",
  [assetId.name]: "",
  [PIN.name]: "",
  [VIN.name]: "",
  [fuel.name]: "",
  [yearOfManufacture.name]: "",
  [hours.name]: "",
  [driver.name]: "",
  [ownership.name]: "",
  [serviceDue.name]: "",
  [type.name]: "",
};

export default initialValues;

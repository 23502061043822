import checkout from "./form";

const {
  formField: { organisationName, organisationAddress, primaryContact, email, phone, lat, lng },
} = checkout;

const initialValues = {
  [organisationName.name]: "",
  [organisationAddress.name]: "",
  [primaryContact.name]: "",
  [email.name]: "",
  [phone.name]: "",
  [lat.name]: "",
  [lng.name]: "",
};

export default initialValues;

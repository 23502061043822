import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ProductImage from "components/ProductImage";
import { useParams } from "react-router-dom";
import { get } from "api";
import decodePolyline from "components/GoogleComponents/decodePolyline";
import ProductInfo from "./components/ProductInfo";
import MachineReports from "./components/Reports";

function FleetDetail() {
  const { id } = useParams();
  const [label, setLabel] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [polylineAvailable, setPolylineAvailable] = useState(false);
  const [polyline, setPolyline] = useState(null);
  const [machineData, setMachineData] = useState(null);
  const [previousMachineData, setPreviousMachineData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const res = await get(`fleet/reports/${id}/date/${selectedDate}/freq/${tabValue}`);
      setMachineData(res.data.report);
      setPreviousMachineData(res.data.previousReport);
      if (res.data.report?.route?.length > 0) {
        setPolylineAvailable(true);
        setPolyline(decodePolyline(res.data.report.route));
      } else {
        setPolylineAvailable(false);
      }
      if (tabValue === 0) {
        setLabel("vs day last week");
        setTimePeriod("Daily");
      } else if (tabValue === 1) {
        setLabel("vs last week");
        setTimePeriod("Weekly");
      } else if (tabValue === 2) {
        setLabel("vs last month");
        setTimePeriod("Monthly");
      }
    }
    fetchData();
  }, [selectedDate, id, tabValue]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <ProductImage />
          </Grid>
          <Grid item xs={12} lg={8}>
            <ProductInfo isActive={machineData?.isActive} />
          </Grid>
          <Grid item xs={12}>
            <MachineReports
              machineData={machineData}
              previousMachineData={previousMachineData}
              label={label}
              timePeriod={timePeriod}
              polylineAvailable={polylineAvailable}
              polyline={polyline}
              selectedDate={selectedDate}
              tabValue={tabValue}
              setTabValue={setTabValue}
              setSelectedDate={setSelectedDate}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FleetDetail;

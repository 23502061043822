import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get } from "api";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import Map from "components/GoogleComponents/map";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import Fleet from "pages/operations/fleet";
import mockLabels from "mocks/productivityData";
import MDBadgeDot from "components/common/MDBadgeDot";
import HorizontalBarChart from "components/Charts/BarCharts/HorizontalBarChart";
import MDButton from "components/common/MDButton";

export default function Overview({ selectedDate, tabValue }) {
  const [loading, setLoading] = useState(true);
  const [machineMarkers, setMachineMarkers] = useState([]);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const site = useSelector((state) => state.sites.sites.find((s) => s.id === id));
  const machines = useSelector((state) => state.fleet.machines.filter((m) => m.siteId.id === id));
  const siteMarkers = site ? [site] : [];
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const res = await get(
        `dashboard/overview?siteId=${id}&selectedDate=${selectedDate}&freq=${tabValue}`
      );
      setMachineMarkers(res.data.machineLocations);
      setData(res.data);
    }
    setLoading(true);
    fetchData().then(() => setLoading(false));
  }, [id, selectedDate, tabValue]);

  return (
    <MDBox mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Map sites={siteMarkers} machines={machineMarkers} />
        </Grid>
        <Grid item xs={12}>
          <MDBox mt={3}>
            <HorizontalBarChart
              title="Utilisation by Type"
              description={
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                    <Tooltip
                      title="Percentage of utilisation by machine type"
                      placement="left"
                      arrow
                    >
                      <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                        <Icon>priority_high</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              }
              chart={data?.pieChartData}
              loading={loading}
              id="myChart"
              labelSymbol="%"
            />
          </MDBox>
        </Grid>
        {data.lineChartData && (
          <Grid item xs={12}>
            <MDBox mt={3}>
              <DefaultLineChart
                color="success"
                title="Materials processed(coming soon)"
                description={
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="info" size="sm" badgeContent="Tonnes(T)" />
                  </MDBox>
                }
                chart={{
                  labels: mockLabels,
                  datasets: [
                    {
                      label: "Cost per ton",
                      data: Array.from({ length: 7 }, () => Math.floor(Math.random() * 100)),
                      color: "info",
                    },
                  ],
                }}
                loading={loading}
              />
            </MDBox>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <DefaultStatisticsCard
            title="Machines"
            count={machines?.length}
            onClick={() => navigate("/fleet")}
            clickable
          />
        </Grid>
        <Grid item xs={12}>
          <MDBox mt={3}>
            <Fleet isEmbedded />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Overview.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  tabValue: PropTypes.number.isRequired,
};

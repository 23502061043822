import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadOrganisations } from "redux/actions/organisations";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import Icon from "@mui/material/Icon";
import DataTable from "components/Tables/DataTable";
import Footer from "layouts/Footer";
import { useNavigate } from "react-router-dom";
import fleetTableData from "./data/organisationTableData";

function Organisation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.organisations?.loading);

  useEffect(() => {
    dispatch(loadOrganisations());
  }, []);

  const { columns, rows } = fleetTableData();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="contained"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" color="white">
                      Organisation
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      color="dark"
                      iconOnly
                      onClick={() => navigate("/organisation/add-new")}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    </MDButton>
                  </Stack>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    clickable
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default Organisation;

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import FormField from "components/FormField";

function ResetPasswordForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { password, confirmPassword } = formField;
  const { password: passwordV, confirmPassword: confirmPasswordV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={confirmPassword.type}
              label={confirmPassword.label}
              name={confirmPassword.name}
              value={confirmPasswordV}
              placeholder={confirmPassword.placeholder}
              error={errors.confirmPassword && touched.confirmPassword}
              success={confirmPasswordV.length > 0 && !errors.confirmPassword}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for SignUp
ResetPasswordForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ResetPasswordForm;

const form = {
  formId: "new-machine-form",
  formField: {
    serialNumber: {
      name: "serialNumber",
      label: "Serial Number",
      type: "text",
      errorMsg: "Serial number is required.",
    },
    make: {
      name: "make",
      label: "Make",
      type: "text",
      errorMsg: "Make is required.",
    },
    model: {
      name: "model",
      label: "Model",
      type: "text",
      errorMsg: "Model is required.",
    },
    siteId: {
      name: "siteId",
      label: "Site",
      type: "text",
      errorMsg: "Site is required.",
    },
    assetId: {
      name: "assetId",
      label: "Asset ID",
      type: "text",
      errorMsg: "Asset ID is required.",
    },
    PIN: {
      name: "PIN",
      label: "PIN",
      type: "text",
      errorMsg: "PIN is required.",
    },
    VIN: {
      name: "VIN",
      label: "VIN",
      type: "text",
      errorMsg: "VIN is required.",
    },
    fuel: {
      name: "fuel",
      label: "Fuel",
      type: "text",
      errorMsg: "Fuel is required.",
    },
    yearOfManufacture: {
      name: "yearOfManufacture",
      label: "Year of Manufacture",
      type: "text",
      errorMsg: "Year of Manufacture is required.",
    },
    hours: {
      name: "hours",
      label: "Hours",
      type: "text",
      errorMsg: "Hours is required.",
    },
    driver: {
      name: "driver",
      label: "Driver",
      type: "text",
      errorMsg: "Driver is required.",
    },
    ownership: {
      name: "ownership",
      label: "Ownership",
      type: "text",
      errorMsg: "Ownership is required.",
    },
    serviceDue: {
      name: "serviceDue",
      label: "Service Due",
      type: "date",
      errorMsg: "Service Due is required.",
    },
    type: {
      name: "type",
      label: "Type",
      type: "text",
      errorMsg: "Type is required.",
    },
  },
};

export default form;

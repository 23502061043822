import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import PropTypes from "prop-types";
import { get } from "api";
import MDBadgeDot from "components/common/MDBadgeDot";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import { Icon, Tooltip } from "@mui/material";
import MDButton from "components/common/MDButton";
import MixedChart from "components/Charts/MixedChart";
import { useParams } from "react-router-dom";
import StackedBarChart from "components/Charts/BarCharts/StackedBarChart";
import mockLabels from "mocks/productivityData";

function Productivity({ selectedDate, tabValue }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const res = await get(
        `fleet/productivity?machineId=${id}&selectedDate=${selectedDate}&freq=${tabValue}`
      );
      setData(res.data);
    }
    setLoading(true);
    fetchData().then(() => setLoading(false));
  }, [selectedDate, tabValue, id]);

  return (
    <MDBox mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mt={3}>
            <StackedBarChart
              icon={{ color: "dark", component: "leaderboard" }}
              title="Fuel consumption"
              chart={data?.graphData?.fuelConsumptionGraphData}
              loading={loading}
              description={
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="info" size="sm" badgeContent="Fuel Consumption(L)" />
                    <MDBadgeDot color="dark" size="sm" badgeContent="Excess Fuel Consumption(L)" />
                  </MDBox>
                </MDBox>
              }
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mt={3}>
            <DefaultLineChart
              title="Utilisation"
              loading={loading}
              description={
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="info" size="sm" badgeContent="Total time(hrs)" />
                    <MDBadgeDot color="secondary" size="sm" badgeContent="Engine running(hrs)" />
                    <MDBadgeDot color="dark" size="sm" badgeContent="Idle time(hrs)" />
                  </MDBox>
                  <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                    <Tooltip title="Compare your daily utilisation" placement="left" arrow>
                      <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                        <Icon>priority_high</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              }
              chart={data?.graphData?.utilisationGraphData}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mt={3}>
            <MixedChart
              icon={{ color: "primary", component: "auto_graph" }}
              title="Mileage"
              description={
                <MDBox display="flex" ml={-1}>
                  <MDBadgeDot color="info" size="sm" badgeContent="Total Mileage(m)" />
                  <MDBadgeDot color="dark" size="sm" badgeContent="Average Speed(m/h)" />
                </MDBox>
              }
              height="19.75rem"
              chart={data.graphData?.speedGraphData}
              loading={loading}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mt={3}>
            <DefaultLineChart
              color="success"
              title="Materials processed(coming soon)"
              description={
                <MDBox display="flex" ml={-1}>
                  <MDBadgeDot color="info" size="sm" badgeContent="Tonnes(T)" />
                </MDBox>
              }
              chart={{
                labels: mockLabels,
                datasets: [
                  {
                    label: "Cost per ton",
                    data: Array.from({ length: 7 }, () => Math.floor(Math.random() * 100)),
                    color: "info",
                  },
                ],
              }}
              loading={loading}
            />
          </MDBox>
        </Grid>
        {tabValue === 0 && (
          <Grid item xs={12}>
            <MDBox mt={3}>
              <MixedChart
                icon={{ color: "primary", component: "auto_graph" }}
                title="Trips"
                description={
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="success" size="sm" badgeContent="Machine On(hrs)" />
                    <MDBadgeDot color="info" size="sm" badgeContent="Active(hrs)" />
                    <MDBadgeDot color="error" size="sm" badgeContent="Idle(hrs)" />
                    <MDBadgeDot color="dark" size="sm" badgeContent="Machine Off(hrs)" />
                  </MDBox>
                }
                height="19.75rem"
                chart={data.tripData}
                loading={loading}
              />
            </MDBox>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}

Productivity.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  tabValue: PropTypes.number.isRequired,
};

export default Productivity;

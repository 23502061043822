/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// React components
import MDInput from "components/common/MDInput";
import { NumericFormat } from "react-number-format";

export default function data(additionalCost, setAdditionalCost, tabValue) {
  return {
    columns: [
      { Header: "Cost", accessor: "cost", width: "45%", align: "left" },
      { Header: "Amount", accessor: "amount", align: "left" },
    ],
    rows:
      additionalCost &&
      additionalCost.map((cost, i) => ({
        cost:
          tabValue === 0 ? (
            <MDInput
              type="text"
              value={cost.costAttribute}
              onChange={(e) => {
                const values = [...additionalCost];
                values[i].costAttribute = e.target.value;
                setAdditionalCost(values);
              }}
              fullWidth
            />
          ) : (
            cost.costAttribute
          ),
        amount:
          tabValue === 0 ? (
            <NumericFormat
              name="amount"
              label="Amount"
              variant="outlined"
              value={cost.costValue}
              size="small"
              onValueChange={(e) => {
                const { floatValue } = e;
                const values = [...additionalCost];
                values[i].costValue = floatValue;
                setAdditionalCost(values);
              }}
              decimalScale={2}
              fixedDecimalScale
              customInput={MDInput}
              thousandSeparator
              prefix="£"
            />
          ) : (
            `£${cost.costValue.toFixed(2)}`
          ),
        id: cost.id,
      })),
  };
}

import { CREATE_ORGANISATION, GET_ORGANISATIONS, LOGOUT, ORGANISATIONS_ERROR } from "../constants";

const initialState = {
  organisations: [],
  loading: true,
  error: null,
};

export default function organisations(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ORGANISATION:
      return {
        ...state,
        organisations: [payload, ...state.organisations],
        loading: false,
      };
    case GET_ORGANISATIONS:
      return {
        ...state,
        organisations: payload,
        loading: false,
      };
    case ORGANISATIONS_ERROR:
      return {
        organisations: [],
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

import { combineReducers } from "redux";

import alert from "./reducers/alert";
import auth from "./reducers/auth";
import organisations from "./reducers/organisations";
import users from "./reducers/users";
import sites from "./reducers/sites";
import fleet from "./reducers/fleet";
import ui from "./reducers/UI";
import options from "./reducers/machineOptions";
import acquire from "./reducers/acquire";

export default combineReducers({
  alert,
  auth,
  organisations,
  users,
  sites,
  fleet,
  ui,
  options,
  acquire,
});

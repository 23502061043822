import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import FormField from "components/FormField";
import GoogleAddressLookup from "../../../../components/GoogleComponents/AddressLookup";

function OrganisationForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { organisationName, organisationAddress, primaryContact, email, phone } = formField;
  const {
    organisationName: organisationNameV,
    organisationAddress: organisationAddressV,
    primaryContact: primaryContactV,
    email: emailV,
    phone: phoneV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add an organisation</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={organisationName.type}
              label={organisationName.label}
              name={organisationName.name}
              value={organisationNameV}
              placeholder={organisationName.placeholder}
              error={errors.organisationName && touched.organisationName}
              success={organisationNameV.length > 0 && !errors.organisationName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GoogleAddressLookup
              marginBottom={1.5}
              type={organisationAddress.type}
              label={organisationAddress.label}
              name={organisationAddress.name}
              value={organisationAddressV}
              placeholder={organisationAddress.placeholder}
              error={errors.organisationAddress && touched.organisationAddress}
              success={organisationAddressV.length > 0 && !errors.organisationAddress}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={primaryContact.type}
              label={primaryContact.label}
              name={primaryContact.name}
              value={primaryContactV}
              placeholder={primaryContact.placeholder}
              error={errors.primaryContact && touched.primaryContact}
              success={primaryContactV.length > 0 && !errors.primaryContact}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phone.type}
              label={phone.label}
              name={phone.name}
              value={phoneV}
              placeholder={phone.placeholder}
              error={errors.phone && touched.phone}
              success={phoneV.length > 0 && !errors.phone}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MachineOptionForm
OrganisationForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default OrganisationForm;

import axios from "axios";
import { API_URL } from "../utils/constants";

const getHeaders = () => ({
  headers: {
    "Content-Type": "application/json",
  },
});

// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}${path}`, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// HTTP PATCH Request - Returns Resolved or Rejected Promise
export const put = (path, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}${path}`, data, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// HTTP POST Request - Returns Resolved or Rejected Promise
export const post = (path, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}${path}`, data, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// HTTP DELETE Request - Returns Resolved or Rejected Promise
export const del = (path) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}${path}`, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

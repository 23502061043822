/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// React components
import { useState } from "react";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDAvatar from "components/common/MDAvatar";
import MDBadge from "components/common/MDBadge";
import team2 from "assets/images/team-2.jpg";
import MDButton from "components/common/MDButton";
import { useDispatch } from "react-redux";
import { updateUserAdmin } from "redux/actions/users";
import Modal from "components/Modal";

export default function AdminUserTableData(users) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdminUpdate = (id) => {
    dispatch(updateUserAdmin(id));
    setOpen(false);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "user", accessor: "user", width: "45%", align: "left" },
      { Header: "admin", accessor: "admin", align: "center" },
      { Header: "edit", accessor: "edit", align: "center" },
    ],

    rows:
      users &&
      users.map((user) => ({
        user: <Author image={team2} name={user?.fullName} email={user.email} />,
        admin: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={user.isAdmin ? "Admin" : "Not admin"}
              color={user.isAdmin ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        edit: (
          <>
            <MDButton
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => setOpen(true)}
            >
              {user.isAdmin ? "Remove privileges" : "Make admin"}
            </MDButton>
            <Modal
              onClose={handleClose}
              open={open}
              title={`${user.isAdmin ? "Remove privileges from " : "Make"} ${user.fullName} ${
                !user.isAdmin && "an admin"
              }`}
              actions={[
                { label: "Yes", onClick: () => handleAdminUpdate(user.id), color: "primary" },
                { label: "No", onClick: handleClose, color: "secondary" },
              ]}
            >
              Are you sure you wish to {user.isAdmin ? "remove privileges from " : "make"}{" "}
              {user.fullName} {!user.isAdmin && "an admin"}?
            </Modal>
          </>
        ),
        id: user.id,
      })),
  };
}

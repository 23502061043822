import {
  CREATE_MACHINE,
  GET_MACHINES,
  MACHINES_ERROR,
  GET_ADMIN_MACHINES,
  LOGOUT,
} from "../constants";

const initialState = {
  machines: [],
  linked: [],
  unlinked: [],
  loading: true,
  error: null,
};

export default function machines(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_MACHINE:
      return {
        ...state,
        machines: [payload, ...state.machines],
        loading: false,
      };
    case GET_MACHINES:
      return {
        ...state,
        machines: payload,
        loading: false,
      };
    case GET_ADMIN_MACHINES:
      return {
        ...state,
        linked: payload[0],
        unlinked: payload[1],
        loading: false,
      };
    case MACHINES_ERROR:
      return {
        machines: [],
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

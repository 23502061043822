export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";

export const CREATE_ORGANISATION = "CREATE_ORG_SUCCESS";
export const GET_ORGANISATIONS = "GET_ORGANISATIONS";
export const ORGANISATIONS_ERROR = "ORGANISATIONS_ERROR";

export const CREATE_USER = "CREATE_USER";
export const GET_USERS = "GET_USERS";
export const USERS_ERROR = "USERS_ERROR";

export const CREATE_SITE = "CREATE_SITE";
export const GET_SITES = "GET_SITES";
export const SITES_ERROR = "SITES_ERROR";

export const CREATE_MACHINE = "CREATE_MACHINE";
export const GET_MACHINES = "GET_MACHINES";
export const MACHINES_ERROR = "MACHINES_ERROR";
export const GET_ADMIN_MACHINES = "GET_ADMIN_MACHINES";

export const CREATE_OPTION = "CREATE_OPTION";
export const GET_OPTIONS = "GET_OPTIONS";
export const OPTIONS_ERROR = "OPTIONS_ERROR";

export const LOAD_FOR_SALE_MACHINES = "LOAD_FOR_SALE_MACHINES";
export const FOR_SALE_MACHINE_FAIL = "FOR_SALE_MACHINE_FAIL";
export const BUY_MACHINE = "BUY_MACHINE";

export const GET_COSTS = "GET_COSTS";
export const CREATE_COST = "CREATE_COST";
export const COSTS_ERROR = "COSTS_ERROR";

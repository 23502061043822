import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { organisationName, organisationAddress, primaryContact, email, phone },
} = checkout;

const validations = [
  Yup.object().shape({
    [organisationName.name]: Yup.string().required(organisationName.errorMsg),
    [organisationAddress.name]: Yup.string().required(organisationAddress.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [primaryContact.name]: Yup.string().required(primaryContact.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg),
    [phone.name]: Yup.string().required(phone.errorMsg),
  }),
];

export default validations;

import { CREATE_USER, GET_USERS, LOGOUT, USERS_ERROR } from "../constants";

const initialState = {
  users: [],
  loading: true,
  error: null,
};

export default function users(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USER:
      return {
        ...state,
        users: [payload, ...state.users],
        loading: false,
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case USERS_ERROR:
      return {
        users: [],
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Map from "components/GoogleComponents/map";
import { get } from "api";

function FleetMap() {
  const lat = useSelector((state) => state.auth?.user?.organisationId?.lat);
  const lng = useSelector((state) => state.auth?.user?.organisationId?.lng);
  const [machineMarkers, setMachineMarkers] = useState([]);
  const siteMarkers = useSelector((state) => state.sites.sites)
    .filter((m) => m?.lat && m?.lng)
    .map((m) => ({
      lat: m?.lat,
      lng: m?.lng,
      key: m?.id,
    }));

  useEffect(() => {
    async function fetchData() {
      const res = await get(`dashboard`);
      setMachineMarkers(res.data.machineLocations);
    }
    fetchData();
  }, []);

  const defaultProps = {
    center: {
      lat,
      lng,
    },
    zoom: 11,
  };
  return (
    <Map defaultProps={defaultProps} sites={siteMarkers} machines={machineMarkers} height="60vh" />
  );
}

export default FleetMap;

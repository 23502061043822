export const setMiniSidenav = (value) => async (dispatch) =>
  dispatch({ type: "MINI_SIDENAV", payload: value });
export const setTransparentSidenav = (value) => async (dispatch) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", payload: value });
export const setWhiteSidenav = (value) => async (dispatch) =>
  dispatch({ type: "WHITE_SIDENAV", payload: value });
export const setSidenavColor = (value) => async (dispatch) =>
  dispatch({ type: "SIDENAV_COLOR", payload: value });
export const setTransparentNavbar = (value) => async (dispatch) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", payload: value });
export const setFixedNavbar = (value) => async (dispatch) =>
  dispatch({ type: "FIXED_NAVBAR", payload: value });
export const setOpenConfigurator = (value) => async (dispatch) =>
  dispatch({ type: "OPEN_CONFIGURATOR", payload: value });
export const setLayout = (value) => async (dispatch) =>
  dispatch({ type: "LAYOUT", payload: value });
export const setDarkMode = (value) => async (dispatch) =>
  dispatch({ type: "DARKMODE", payload: value });

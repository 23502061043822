import { Link, Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/equip-go-banner.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import initialValues from "./schemas/initialValues";
import signInForm from "./schemas/sign-in-form";
import { login } from "../../redux/actions/auth";
import validations from "./schemas/validations";
import UserInfo from "./components/SignInForm";

function SignIn() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { formId, formField } = signInForm;

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  const handleSubmit = async (values, actions) => {
    dispatch(login(values));
    actions.setSubmitting(false);
    // actions.resetForm();
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <Formik
          initialValues={initialValues[1]}
          validationSchema={validations[1]}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox p={3}>
                <MDBox>
                  <UserInfo
                    formData={{
                      values,
                      touched,
                      formField,
                      errors,
                    }}
                  />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting}
                      type="submit"
                    >
                      sign in
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Forgot your password?{" "}
                    <MDTypography
                      component={Link}
                      to="/forgot-password"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Reset
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Form>
          )}
        </Formik>
      </Card>
    </CoverLayout>
  );
}

export default SignIn;

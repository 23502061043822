import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";

const K_WIDTH = 30;
const K_HEIGHT = 30;

const markerStyle = {
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,
  border: "5px solid #fb8c00",
  borderRadius: K_HEIGHT,
  backgroundColor: "white",
  textAlign: "center",
  color: "#2c3c58",
  fontSize: 10,
  fontWeight: "bold",
  padding: 1,
};

export default function Marker({ isSite }) {
  return (
    <div style={markerStyle}>
      <Icon fontSize="small" color="inherit">
        {isSite ? "location_on" : "local_shipping"}
      </Icon>
    </div>
  );
}

Marker.defaultProps = {
  isSite: false,
};

Marker.propTypes = {
  isSite: PropTypes.bool,
};

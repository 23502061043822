import React from "react";
import { useSelector } from "react-redux";
import Map from "components/GoogleComponents/map";

function FleetMap() {
  const lat = useSelector((state) => state.auth?.user?.organisationId?.lat);
  const lng = useSelector((state) => state.auth?.user?.organisationId?.lng);
  const siteMarkers = useSelector((state) => state.sites.sites)
    .filter((m) => m?.lat && m?.lng)
    .map((m) => ({
      lat: m?.lat,
      lng: m?.lng,
      key: m?.id,
    }));

  const defaultProps = {
    center: {
      lat,
      lng,
    },
    zoom: 8,
  };
  return <Map defaultProps={defaultProps} sites={siteMarkers} height="60vh" />;
}

export default FleetMap;

import { useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDAvatar from "components/common/MDAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import useOrgaisation from "hooks/useOrgaisation";
import MDButton from "components/common/MDButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadAllUsers } from "redux/actions/users";

function Header() {
  const navigate = useNavigate();
  const organisation = useOrgaisation();
  const handleUserAdd = () => {
    navigate("/organisation/user/add-new", { state: { organisationId: organisation.id } });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllUsers(organisation.id));
  }, []);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {organisation.organisationName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {organisation.organisationAddress}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox display="flex" justifyContent="flex-end" alignItems="center">
              <MDButton variant="outlined" color="secondary" onClick={handleUserAdd}>
                add user
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;

import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Footer from "layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "components/Tables/DataTable";
import fleetTableData from "pages/operations/fleet/data/fleetTableData";
import MDButton from "components/common/MDButton";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import { CircularProgress } from "@mui/material";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import { useEffect, useState } from "react";
import { loadAllMachines } from "redux/actions/fleet";
import FleetMap from "./fleetMap";
import getStatus from "../../../utils/fleetFunctions";

function Fleet({ isEmbedded }) {
  const dispatch = useDispatch();
  const [machineWithStatus, setMachineWithStatus] = useState([]);
  useEffect(() => {
    dispatch(loadAllMachines());
  }, []);
  const { id: siteId } = useParams();

  // if is embedded return only the machines with the siteId
  const fleet = useSelector((state) =>
    isEmbedded
      ? state.fleet.machines.filter((machine) => machine.siteId.id === siteId)
      : state.fleet.machines
  );

  const loading = useSelector((state) => state.fleet.loading);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMachineStatus = async () => {
      const machineWithStatusPromise = await Promise.all(
        fleet.map(async (machine) => {
          const isActive = await getStatus(machine);
          return { ...machine, isActive };
        })
      );
      setMachineWithStatus(machineWithStatusPromise);
    };
    fetchMachineStatus();
  }, []);

  const { columns, rows } = fleetTableData(machineWithStatus);

  // return without DashboardLayout and DashboardNavbar if embedded
  if (isEmbedded)
    return (
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Fleet
                  </MDTypography>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    iconOnly
                    onClick={() => navigate("/fleet/add-new")}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    sx={{ py: 10 }}
                  >
                    <CircularProgress color="info" size={100} />
                  </MDBox>
                ) : (
                  <DataTable
                    clickable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    baseRoute="/fleet"
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DefaultStatisticsCard title="Total Fleet" count={fleet?.length} />
          </Grid>
          <Grid item xs={6}>
            <DefaultStatisticsCard
              title="Fleet Active"
              count={machineWithStatus.filter((f) => f.isActive.data === true).length}
            />
          </Grid>
          <FleetMap />
        </Grid>
        <MDBox pt={6} pb={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="contained"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" color="white">
                      Fleet
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      color="dark"
                      iconOnly
                      onClick={() => navigate("/fleet/add-new")}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    </MDButton>
                  </Stack>
                </MDBox>
                <MDBox pt={3}>
                  {loading ? (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      sx={{ py: 10 }}
                    >
                      <CircularProgress color="info" size={100} />
                    </MDBox>
                  ) : (
                    <DataTable
                      clickable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Fleet.defaultProps = {
  isEmbedded: false,
};

Fleet.propTypes = {
  isEmbedded: PropTypes.bool,
};

export default Fleet;

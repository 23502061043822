import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDButton from "components/common/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { deleteOption, saveOption, updateOption } from "redux/actions/machineOptions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MachineOptionForm from "./components/MachineOptionForm";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

function MachineOptionsAdd() {
  const { id } = useParams();
  const options = useSelector((state) => state.options.options.find((opt) => opt.id === id));
  const [isEdit, setIsEdit] = useState(false);
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsEdit(id !== "0");
  }, [id]);

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await dispatch(isEdit ? updateOption(id, values) : saveOption(values));
      navigate("/admin/machineDetails");
      actions.setSubmitting(false);
    } catch {
      actions.setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    await dispatch(deleteOption(id));
    navigate("/admin/machineDetails");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={isEdit ? options : initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MachineOptionForm
                        formData={{
                          values,
                          touched,
                          formField,
                          errors,
                          isSubmitting,
                          isEdit,
                        }}
                      />
                      <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                        {isEdit && (
                          <MDBox mr={2}>
                            <MDButton
                              disabled={isSubmitting}
                              type="button"
                              variant="gradient"
                              color="dark"
                              onClick={handleDelete}
                            >
                              Delete
                            </MDButton>
                          </MDBox>
                        )}

                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {isEdit ? "update" : "add"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MachineOptionsAdd;

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/common/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import Icon from "@mui/material/Icon";
import DataTable from "components/Tables/DataTable";
import Footer from "layouts/Footer";
import { loadAllSites } from "redux/actions/sites";
import { useNavigate } from "react-router-dom";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import siteTableData from "./data/sitesTableData";
import SiteMap from "./siteMap";

function Sites() {
  const machines = useSelector((state) => state.fleet.machines);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSiteAdd = () => {
    navigate("/sites/add-new");
  };
  const sites = useSelector((state) => state.sites.sites);

  // load all sites
  useEffect(() => {
    dispatch(loadAllSites());
  }, []);

  const { columns, rows } = siteTableData(sites);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DefaultStatisticsCard
              title="Total Fleet"
              count={machines?.length}
              onClick={() => navigate("/fleet")}
              clickable
            />
          </Grid>
          <Grid item xs={6}>
            <DefaultStatisticsCard
              title="Total Sites"
              count={sites?.length}
              onClick={() => navigate("/sites")}
              clickable
            />
          </Grid>
          <SiteMap />
        </Grid>
        <MDBox pt={6} pb={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="contained"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" color="white">
                      Sites
                    </MDTypography>
                    <MDButton variant="outlined" color="dark" iconOnly onClick={handleSiteAdd}>
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    </MDButton>
                  </Stack>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    clickable
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Sites;

import { LOAD_FOR_SALE_MACHINES, FOR_SALE_MACHINE_FAIL, LOGOUT } from "../constants";

const initialState = {
  machinesForSale: [],
  loading: true,
  error: null,
};

export default function machines(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FOR_SALE_MACHINES:
      return {
        ...state,
        machinesForSale: payload,
        loading: false,
      };
    case FOR_SALE_MACHINE_FAIL:
      return {
        machinesForSale: [],
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

import { v4 as uuid } from "uuid";

import { REMOVE_ALERT, SET_ALERT } from "../constants";

const setAlert =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
    const id = uuid();
    window.scrollTo(0, 0);
    dispatch({
      type: SET_ALERT,
      payload: { msg: msg.replace(/\u001b\[[0-9]{1,2}m/g, ""), alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

export default setAlert;

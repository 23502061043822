import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import DataTable from "components/Tables/DataTable";
import MDButton from "components/common/MDButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadAllOptions } from "redux/actions/machineOptions";

function MachineDetailsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.ui.darkMode);

  // load machine options
  useEffect(() => {
    dispatch(loadAllOptions());
  }, [dispatch]);

  const options = useSelector((state) => state.options.options);

  const dataTableData = {
    columns: [
      { Header: "make", accessor: "make" },
      { Header: "model", accessor: "model" },
      { Header: "operations", accessor: "operations" },
      { Header: "fuel", accessor: "fuel" },
      { Header: "image URL", accessor: "imageURL" },
    ],
    rows: options,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={2}>
            <MDButton
              onClick={() => navigate("/admin/machineDetails/0")}
              color={darkMode ? "info" : "secondary"}
              variant="contained"
            >
              Add machine Options
            </MDButton>
          </MDBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MachineDetailsList;

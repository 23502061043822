import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import MDBadge from "components/common/MDBadge";
import { buyMachine } from "redux/actions/acquire";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatCurrency } from "utils/numberFunctions";

function ProductInfo() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const machine = useSelector((state) => state.acquire.machinesForSale.find((m) => m.id === id));

  const handleBuy = () => {
    dispatch(buyMachine(id));
  };

  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          {machine?.make} {machine?.model}
        </MDTypography>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Price
        </MDTypography>
      </MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {formatCurrency(machine?.price)}
        </MDTypography>
      </MDBox>
      <MDBadge variant="contained" color="success" badgeContent="For Sale" container />

      {machine?.description && (
        <>
          <MDBox mt={3} mb={1} ml={0.5}>
            <MDTypography variant="h5" fontWeight="regular" color="text">
              Description
            </MDTypography>
          </MDBox>
          <MDBox m={0} mb={2}>
            <MDBox color="text" variant="p" lineHeight={1}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                verticalAlign="middle"
              >
                {machine?.description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </>
      )}
      <MDBox mt={3}>
        <Grid item xs={12} lg={5} container>
          <MDButton variant="gradient" color="info" fullWidth onClick={handleBuy}>
            Buy machine
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ProductInfo;

import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDButton from "components/common/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { useDispatch } from "react-redux";
import { saveOrganisation } from "redux/actions/organisations";
import setAlert from "redux/actions/alert";
import OrganisationForm from "./components/OrganisationForm";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

function AddNewOrganisation() {
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values, actions) => {
    if (values.lat && values.lng) {
      actions.setSubmitting(true);
      try {
        dispatch(saveOrganisation(values));
        navigate("/organisation");
      } catch {
        actions.setSubmitting(false);
      }
    } else {
      dispatch(setAlert("Please select an address from the dropdown list", "error"));
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <OrganisationForm
                        formData={{
                          values,
                          touched,
                          formField,
                          errors,
                          isSubmitting,
                        }}
                      />
                      <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          add
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewOrganisation;

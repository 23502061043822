import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import productImage from "assets/images/missing-image.jpeg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function ProductImage() {
  const { id } = useParams();
  const imageURL = useSelector((state) => state.fleet.machines.find((m) => m.id === id).imageURL);
  return (
    <Card>
      <MDBox
        component="img"
        src={imageURL || productImage}
        alt="Product Image"
        borderRadius="lg"
        shadow="sm"
        width="100%"
        height="100%"
        position="relative"
        zIndex={10}
      />
    </Card>
  );
}

export default ProductImage;

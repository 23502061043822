import { get, post } from "api";
import setAlert from "./alert";
import { LOAD_FOR_SALE_MACHINES, FOR_SALE_MACHINE_FAIL, BUY_MACHINE } from "../constants";

export const getMachinesForSale = () => async (dispatch) => {
  try {
    const res = await get("acquire");
    dispatch({
      type: LOAD_FOR_SALE_MACHINES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("Failed to load machines for sale", "error"));
    }

    dispatch({
      type: FOR_SALE_MACHINE_FAIL,
    });
  }
};

export const buyMachine = (id) => async (dispatch) => {
  try {
    const res = await post(`acquire/${id}`);
    dispatch({
      type: BUY_MACHINE,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
      }
    } else {
      dispatch(setAlert("Failed to load machines for sale", "error"));
    }

    dispatch({
      type: FOR_SALE_MACHINE_FAIL,
    });
  }
};

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import FormField from "components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import selectData from "./selectData";

function MachineOptionForm({ formData }) {
  const { setFieldValue } = useFormikContext();
  const { formField, values, errors, touched, isEdit } = formData;
  const { make, model, operations, fuel, imageURL, kwh } = formField;
  const {
    make: makeV,
    model: modelV,
    operations: operationsV,
    fuel: fuelV,
    imageURL: imageURLV,
    kwh: kwhV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">{isEdit ? "Edit" : "Add"} a Machine</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={make.type}
              label={make.label}
              name={make.name}
              value={makeV}
              placeholder={make.placeholder}
              error={errors.n && touched.make}
              success={makeV?.length > 0 && !errors.make}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={model.type}
              label={model.label}
              name={model.name}
              value={modelV}
              placeholder={model.placeholder}
              error={errors.n && touched.model}
              success={modelV?.length > 0 && !errors.model}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={selectData.operations}
              onChange={(e, value) => setFieldValue("operations", value)}
              renderInput={(params) => (
                <FormField
                  {...params}
                  marginBottom={1.5}
                  type={operations.type}
                  label={operations.label}
                  name={operations.name}
                  value={operationsV}
                  placeholder={operations.placeholder}
                  error={errors.n && touched.operations}
                  success={operationsV?.length > 0 && !errors.operations}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={selectData.fuel}
              onChange={(e, value) => setFieldValue("fuel", value)}
              renderInput={(params) => (
                <FormField
                  {...params}
                  marginBottom={1.5}
                  type={fuel.type}
                  label={fuel.label}
                  name={fuel.name}
                  value={fuelV}
                  placeholder={fuel.placeholder}
                  error={errors.n && touched.fuel}
                  success={fuelV?.length > 0 && !errors.fuel}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              marginBottom={1.5}
              type={imageURL.type}
              label={imageURL.label}
              name={imageURL.name}
              value={imageURLV}
              placeholder={imageURL.placeholder}
              error={errors.n && touched.imageURL}
              success={imageURLV?.length > 0 && !errors.imageURL}
            />
          </Grid>
          {fuelV === "Electric" && (
            <Grid item xs={12} sm={6}>
              <FormField
                marginBottom={1.5}
                type={kwh.type}
                label={kwh.label}
                name={kwh.name}
                value={kwhV}
                placeholder={kwh.placeholder}
                error={errors.n && touched.kwh}
                success={kwhV?.length > 0 && !errors.imageURL}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MachineOptionForm
MachineOptionForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MachineOptionForm;

import { Link, Navigate } from "react-router-dom";
import { register } from "redux/actions/auth";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/equip-go-banner.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import signUpForm from "./schemas/sign-up-form";
import UserInfo from "./components/SignUp";

function SignUp() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { formId, formField } = signUpForm;

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async (values, actions) => {
    dispatch(register(values));
    actions.setSubmitting(false);
    actions.resetForm();
  };
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <Formik
          initialValues={initialValues[0]}
          validationSchema={validations[0]}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox p={3}>
                <MDBox>
                  <UserInfo
                    formData={{
                      values,
                      touched,
                      formField,
                      errors,
                    }}
                  />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting}
                      type="submit"
                    >
                      sign up
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Form>
          )}
        </Formik>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;

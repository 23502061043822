import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import MDSnackbar from "./common/MDSnackbar";
import { REMOVE_ALERT } from "../redux/constants";

function Alert({ alerts }) {
  const dispatch = useDispatch();
  const handleClose = (id) => dispatch({ type: REMOVE_ALERT, payload: id });
  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <MDSnackbar
        color={alert.alertType}
        icon={alert.alertType}
        content={alert.msg}
        open
        close={() => handleClose(alert.id)}
        key={alert.id}
      />
    ))
  );
}

Alert.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      msg: PropTypes.string.isRequired,
      alertType: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired
  ),
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);

// function to return thousands with comma and round to 2 decimal places and add custom label
export function formatNumber(num, unit, loading) {
  if (loading) return "Loading...";
  if (num) {
    return `${(Math.round(num * 100) / 100).toLocaleString()} ${unit}`;
  }
  return `No data available`;
}

// function to return thousands with comma and round to 2 decimal places and add currency symbol at the beginning
export function formatCurrency(num, loading) {
  if (loading) return "Loading...";
  if (num) {
    return `£${(Math.round(num * 100) / 100).toLocaleString()}`;
  }
  return `No data available`;
}

// function to compare current and previous data and return the difference in percentage and color if positive or negative
export function compareData(current, previous, label) {
  if (current && previous) {
    const difference = (current - previous) / previous;
    const color = difference > 0 ? "error" : "success";
    return {
      value: `${(difference * 100).toFixed(2)}%`,
      color,
      label,
    };
  }
  return {
    value: `${0}%`,
    color: "success",
    label,
  };
}

// format time to hours if over 60 minutes and minutes if less than 60 minutes with custom label
export function formatTime(time, loading) {
  if (loading) return "Loading...";
  const minutes = time / 60;
  if (minutes > 60) {
    return `${(minutes / 60).toFixed(2)} hours`;
  }
  return Number.isNaN(minutes) ? "No data available" : `${minutes.toFixed(2)} minutes`;
}

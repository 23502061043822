import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import Stack from "@mui/material/Stack";
import MDTypography from "components/common/MDTypography";
import MDButton from "components/common/MDButton";
import Icon from "@mui/material/Icon";
import { CircularProgress } from "@mui/material";
import DataTable from "components/Tables/DataTable";
import { useEffect, useState } from "react";
import { get, post } from "api";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import setAlert from "redux/actions/alert";
import additionalCostsData from "./data/financeTableData";

function AdditionalCosts({ tabValue, additionalCost, setAdditionalCost, siteId, selectedDate }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const blankCost = {
    siteId,
    costAttribute: "",
    costValue: 0,
    date: selectedDate,
  };
  const addAdditionalCost = () => {
    setAdditionalCost([...additionalCost, { ...blankCost }]);
  };

  const handleSave = () => {
    setLoading(true);
    post("costs", additionalCost)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          const { errors } = err.response.data;

          if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
          }
        } else {
          dispatch(setAlert("Server error", "error"));
        }
      });
  };

  const fetchCosts = async () => {
    setLoading(true);
    const res = await get(`costs/${siteId}?date=${selectedDate}&freq=${tabValue}`);
    setAdditionalCost(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchCosts()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [siteId, selectedDate, tabValue]);

  const { columns, rows } = additionalCostsData(additionalCost, setAdditionalCost, tabValue);
  return (
    <MDBox mt={3}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="contained"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6" color="white">
              Additional Costs
            </MDTypography>
            {tabValue === 0 && (
              <MDButton variant="outlined" color="dark" iconOnly onClick={addAdditionalCost}>
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              </MDButton>
            )}
          </Stack>
        </MDBox>
        <MDBox pt={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              sx={{ py: 10 }}
            >
              <CircularProgress color="info" size={100} />
            </MDBox>
          ) : (
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              loading={loading}
            />
          )}
          {tabValue !== 0 ? (
            <MDBox mx={2} mt={3} variant="contained" borderRadius="lg">
              <MDTypography variant="h6" color="dark">
                To add additional costs, please choose day and a date to associate it with
              </MDTypography>
            </MDBox>
          ) : (
            <MDButton
              variant="contained"
              color="dark"
              fullWidth
              onClick={handleSave}
              mb={3}
              disabled={additionalCost.length === 0}
            >
              Save
            </MDButton>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

// prop types
AdditionalCosts.propTypes = {
  tabValue: PropTypes.number.isRequired,
  additionalCost: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.string.isRequired,
      costAttribute: PropTypes.string.isRequired,
      costValue: PropTypes.number.isRequired,
    })
  ).isRequired,
  setAdditionalCost: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default AdditionalCosts;

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const useOrgaisation = () => {
  const { id } = useParams();
  const organisation = useSelector((state) =>
    state.organisations.organisations.find((org) => org.id === id)
  );
  return organisation;
};

export default useOrgaisation;

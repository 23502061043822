import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/common/MDBox";
import MDButton from "components/common/MDButton";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/common/MDTypography";
import MDInput from "components/common/MDInput";
import { updateMachine } from "redux/actions/fleet";

function AddSensorIdent() {
  const { id } = useParams();
  const machine = useSelector((state) => state.fleet.machines.find((m) => m.id === id));
  const [ident, setIdent] = useState(machine?.ident || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    await dispatch(updateMachine(id, { ident }));
    navigate("/admin/linkTable");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox lineHeight={0}>
                    <MDTypography variant="h5">Add sensor</MDTypography>
                    <MDTypography variant="button" color="text">
                      Add sensor to the machine
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={1.625}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <MDBox lineHeight={0} my={1}>
                          <MDTypography variant="button" color="text">
                            Serial number
                          </MDTypography>
                          <MDTypography>{machine?.serialNumber}</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDBox lineHeight={0} my={1}>
                          <MDTypography variant="button" color="text">
                            Site name
                          </MDTypography>
                          <MDTypography>{machine?.siteId?.siteName}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <MDBox lineHeight={0} my={1}>
                          <MDTypography variant="button" color="text">
                            Site name
                          </MDTypography>
                          <MDTypography>{machine?.make}</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDBox lineHeight={0} my={1}>
                          <MDTypography variant="button" color="text">
                            Site name
                          </MDTypography>
                          <MDTypography>{machine?.model}</MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDInput
                          variant="standard"
                          marginBottom={1.5}
                          type="text"
                          label="Sensor Ident"
                          name="ident"
                          value={ident}
                          placeholder="Add sensor ident"
                          fullWidth
                          onChange={(e) => setIdent(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
                <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                  <MDButton type="button" variant="gradient" color="dark" onClick={handleSubmit}>
                    add
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddSensorIdent;
